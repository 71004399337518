import { useState, useMemo, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { initSession } from 'store/session/thunks';
import { AudienceType } from 'utils/constants/audienceType';
import './App.css';
import PrivateRoute from './components/privateRoute/PrivateRoute';
import { SnackbarMessage } from './components/snackbar/Snackbar';
import SnackContext from './store/features/contextSnackbar';
import { selectIsSessionConfirmed, selectLogInStatus } from './store/session/selectors';
import { useAppDispatch, useAppSelector } from './utils/hooks/storeHooks';
import Layout from './views/Layout';
import AdminList from './views/adminList/AdminList';
import AnnouncementForm from './views/announcementForm/AnnouncementForm';
import Announcements from './views/announcements/Announcements';
import BackgroundForm from './views/backgroundForm/BackgroundForm';
import Backgrounds from './views/backgrounds/Backgrounds';
import Dashboard from './views/dashboard/Dashboard';
import { EditGroup } from './views/editGroup/EditGroup';
import Groups from './views/groups/Groups';
import Login from './views/login/Login';
import NotFound from './views/notFound/NotFound';

function App() {
  const [snackPack, setSnackPack] = useState<SnackbarMessage[]>([]);
  const snackContextValue = useMemo(() => ({ snackPack, setSnackPack }), [snackPack, setSnackPack]);
  const dispatch = useAppDispatch();
  const hasSessionBeenChecked = useAppSelector(selectIsSessionConfirmed);
  const sessionStatus = useAppSelector(selectLogInStatus);

  useEffect(() => {
    if (!hasSessionBeenChecked) {
      dispatch(initSession());
    }
  }, [hasSessionBeenChecked, dispatch, sessionStatus]);

  return (
    <div className='App'>
      {/* All the routes are passed as children to this component */}
      <SnackContext.Provider value={snackContextValue}>
        <Routes>
          {/* ==========================  P U B L I C  ========================== */}
          {/* Login */}
          <Route element={<Login />} path='login' />

          {/* ==========================  P R I V A T E (requires auth)  ========================== */}
          {/* ==========================    W I T H    S I D E B A R     ========================== */}
          <Route element={<Layout />} path='/'>
            {/* Dashboard */}
            <Route
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
              index
            />
            <Route
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
              path='dashboard'
            />

            {/* Backgrounds */}
            <Route
              element={
                <PrivateRoute>
                  <Backgrounds />
                </PrivateRoute>
              }
              path='background'
            />

            {/* Announcements */}
            <Route
              element={
                <PrivateRoute>
                  <Announcements />
                </PrivateRoute>
              }
              path='announcements'
            />

            {/* Admin List */}
            <Route
              element={
                <PrivateRoute>
                  <AdminList />
                </PrivateRoute>
              }
              path='admin-list'
            />

            {/* Groups */}
            <Route
              element={
                <PrivateRoute>
                  <Groups />
                </PrivateRoute>
              }
              path='groups'
            />

            {/* ==========================  I N E X I S T A N T  ========================== */}
            <Route
              element={
                <PrivateRoute>
                  <NotFound />
                </PrivateRoute>
              }
              path='/not-found'
            />
            <Route element={<Navigate replace to='/not-found' />} path='*' />
          </Route>

          {/* ==========================  P R I V A T E (requires auth)  ========================== */}
          {/* ==========================  W I T H O U T   S I D E B A R  ========================== */}

          {/* Backgrounds */}
          <Route
            element={
              <PrivateRoute>
                <BackgroundForm audienceType={AudienceType.Group} />
              </PrivateRoute>
            }
            path='background/create/groups'
          />
          <Route
            element={
              <PrivateRoute>
                <BackgroundForm audienceType={AudienceType.Individual} />
              </PrivateRoute>
            }
            path='background/create/individuals'
          />
          <Route
            element={
              <PrivateRoute>
                <BackgroundForm audienceType={AudienceType.Group} service='edit' />
              </PrivateRoute>
            }
            path='background/edit/groups/:id'
          />
          <Route
            element={
              <PrivateRoute>
                <BackgroundForm audienceType={AudienceType.Individual} service='edit' />
              </PrivateRoute>
            }
            path='background/edit/individuals/:id'
          />

          {/* Announcements */}
          <Route
            element={
              <PrivateRoute>
                <AnnouncementForm audienceType={AudienceType.Group} />
              </PrivateRoute>
            }
            path='announcements/create/groups'
          />
          <Route
            element={
              <PrivateRoute>
                <AnnouncementForm audienceType={AudienceType.Individual} />
              </PrivateRoute>
            }
            path='announcements/create/individuals'
          />
          <Route
            element={
              <PrivateRoute>
                <AnnouncementForm audienceType={AudienceType.Group} service='edit' />
              </PrivateRoute>
            }
            path='announcements/edit/groups/:id'
          />
          <Route
            element={
              <PrivateRoute>
                <AnnouncementForm audienceType={AudienceType.Individual} service='edit' />
              </PrivateRoute>
            }
            path='announcements/edit/individuals/:id'
          />

          {/* Groups */}
          <Route
            element={
              <PrivateRoute>
                <EditGroup />
              </PrivateRoute>
            }
            path='groups/edit/:groupId'
          />
        </Routes>
      </SnackContext.Provider>
    </div>
  );
}

export default App;
