import { Group as GroupType } from 'type/group';
import { SynkronUser } from 'type/user';

export enum AudienceType {
  individual = 'individual',
  group = 'group',
  Individual = 'Individual',
  Group = 'Group',
}

export const getAudienceOptions = (availableAudience: Array<GroupType | SynkronUser>, byGroup: boolean) =>
  availableAudience.map((audience) => {
    const value = byGroup ? (audience as GroupType).name : (audience as SynkronUser).fullName;
    return {
      label: value,
      value,
    };
  });
