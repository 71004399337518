import {
  createTheme,
  paginationItemClasses,
  paperClasses,
  svgIconClasses,
  tableCellClasses,
  tableContainerClasses,
  tablePaginationClasses,
  inputBaseClasses,
  selectClasses,
  outlinedInputClasses,
  inputLabelClasses,
  formHelperTextClasses,
  chipClasses,
  menuItemClasses,
  listItemIconClasses,
  listClasses,
  typographyClasses,
  dialogActionsClasses,
  dialogTitleClasses,
  dialogContentClasses,
  tableRowClasses,
} from '@mui/material';
import {
  accentBlue,
  backgroundFocusBlue,
  backgroundWhite,
  black,
  destructiveRed2,
  disabledGray1,
  disabledGray2,
  focusPurple,
  neutralGray,
  paragraphBaseline,
  paragraphPlaceholder,
  primaryBlue,
  primaryHoverBlue1,
  primaryHoverBlue2,
  secondaryBlue,
} from '../../styles/partials/variables';

/* ==================== PALETTE OF COLORS ==================== */
declare module '@mui/material/styles' {
  interface Palette {
    accentBlue: Palette['primary'];
    primaryHoverBlue1: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    accentBlue?: PaletteOptions['primary'];
    primaryHoverBlue1?: PaletteOptions['primary'];
  }
}

/* ====================== PROPS OVERRIDES ====================== */
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    azulChip: true;
    azulPrimary: true;
    azulSecondary: true;
    azulBackLink: true;
    microsoftLogin: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    azulAvatarChip: true;
  }
  interface ChipPropsColorOverrides {
    accentBlue: true;
    primaryHoverBlue1: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    avatarInitial: true;
    canvasSubtitle: true;
    canvasTitle: true;
    colorPickerInputLabel: true;
    icon: true;
    link: true;
    loginHeader: true;
    paragraph: true;
    paragraph12: true;
    paragraph14: true;
    textColorTool: true;
    textSizeTool: true;
    viewHeader: true;
  }
}

/* ==================== CUSTOMIZING THE THEME ==================== */
export const azulDesign = createTheme({
  components: {
    /* ==================== CONTAINER ==================== */
    MuiContainer: {
      variants: [
        {
          props: { className: 'custom-color-picker-layout' },
          style: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            rowGap: '26px',
            '.react-colorful': {
              width: '100%',
              height: '265px',
            },
            '.react-colorful__saturation': {
              margin: '0 0 15px 0',
              borderRadius: '8px',
              borderBottom: 'none',
              height: '238px',
            },
            '.react-colorful__pointer': {
              width: '32px',
              height: '32px',
              border: `8px solid ${backgroundWhite}`,
              outline: `1px solid rgba(0, 0, 0, 0.10)`,
            },
            '.react-colorful__hue': {
              height: '12px',
              borderRadius: '24px',
            },
          },
        },
      ],
    },
    /* ==================== BUTTON ==================== */
    MuiButton: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application
      },
      variants: [
        {
          props: { variant: 'azulChip' },
          style: {
            backgroundColor: backgroundWhite,
            border: `1px solid ${primaryBlue}`,
            borderRadius: '8px',
            color: primaryBlue,
            fontSize: '0.875rem',
            lineHeight: '24px',
            fontWeight: 400,
            height: '32px',
            letterSpacing: '0.002em',
            padding: '4px 16px',
            gap: '8px',
            textTransform: 'capitalize',
            marginRight: '0.5rem',
            '&:hover': {
              cursor: 'pointer',
              border: `1px solid ${primaryBlue}`,
              backgroundColor: accentBlue,
            },
            '&:focus-visible': {
              outline: `2px solid ${focusPurple}`,
            },
            '&:focus': {
              outline: 'none',
              backgroundColor: primaryHoverBlue1,
            },
            '&:active': {
              backgroundColor: primaryHoverBlue1,
              outline: 'none',
            },
            '&:disabled': {
              borderColor: disabledGray2,
              color: disabledGray2,
            },
          },
        },
        {
          props: { variant: 'azulPrimary' },
          style: {
            backgroundColor: primaryBlue,
            border: `1px solid ${primaryBlue}`,
            borderRadius: '4px',
            boxShadow: 'none',
            color: backgroundWhite,
            fontSize: '1rem',
            fontWeight: 600,
            height: '40px',
            letterSpacing: '0.01em',
            padding: '8px 16px 8px 16px',
            marginLeft: '1rem',
            textTransform: 'capitalize',

            '&:hover': {
              backgroundColor: primaryHoverBlue2,
              boxShadow: 'none',
            },
            '&:focus-visible': {
              outline: `2px solid ${focusPurple}`,
            },
            '&:disabled': {
              color: disabledGray2,
              backgroundColor: disabledGray1,
              border: 'none',
            },
          },
        },
        {
          props: { variant: 'azulSecondary' },
          style: {
            backgroundColor: backgroundWhite,
            border: `1px solid ${primaryBlue}`,
            borderRadius: '4px',
            color: primaryBlue,
            fontSize: '1rem',
            fontWeight: 600,
            height: '40px',
            letterSpacing: '0.01em',
            padding: '8px 16px 8px 16px',
            marginLeft: '1rem',
            textTransform: 'capitalize',
            '&:hover': {
              border: `1px solid ${primaryBlue}`,
              backgroundColor: accentBlue,
            },
            '&:focus-visible': {
              outline: `2px solid ${focusPurple}`,
            },
            '&:active': {
              backgroundColor: primaryHoverBlue1,
              outline: 'none',
            },
            '&:disabled': {
              borderColor: disabledGray2,
              color: disabledGray2,
            },
          },
        },
        {
          props: { variant: 'azulBackLink' },
          style: {
            all: 'unset',
            alignItems: 'center',
            backgroundColor: 'none',
            color: primaryBlue,
            cursor: 'pointer',
            display: 'flex',
            fontSize: '1rem',
            fontWeight: 600,
            height: 32,
            lineHeight: '24px',
            textDecoration: 'none',
            '&:hover': {
              backgroundColor: 'unset',
            },
            '&:disabled': {
              color: disabledGray2,
            },
          },
        },
        {
          props: { variant: 'microsoftLogin' },
          style: {
            backgroundColor: backgroundWhite,
            border: `1px solid #5e5e5e`,
            borderRadius: '0',
            boxShadow: 'none',
            color: '#5e5e5e',
            fontSize: '1rem',
            fontWeight: 500,
            height: '41px',
            letterSpacing: '0.01em',
            lineHeight: '24px',
            padding: '11px 14px',
            marginLeft: '1rem',
            textTransform: 'none',
            justifyContent: 'space-around',
            width: '235px',
            '&:hover': {
              backgroundColor: backgroundWhite,
              boxShadow: 'none',
            },
            '&:focus-visible': {
              outline: `2px solid ${focusPurple}`,
            },
            '&:disabled': {
              color: disabledGray2,
              backgroundColor: disabledGray1,
              border: 'none',
            },
          },
        },
      ],
    },
    /* ==================== CHIP ==================== */
    MuiChip: {
      defaultProps: {
        color: 'primaryHoverBlue1',
      },
      variants: [
        {
          props: { variant: 'azulAvatarChip' },
          style: {
            borderRadius: '100px',
            height: '2.7rem',
            padding: '0.1rem',
            '&:hover': {
              backgroundColor: accentBlue,
            },
            '&:active': {
              backgroundColor: primaryHoverBlue1,
            },
            '&.Mui-focusVisible': {
              backgroundColor: accentBlue,
              outline: `2px solid ${focusPurple}`,
            },
            [`.${chipClasses.label}`]: {
              paddingLeft: '0.5rem',
              paddingRight: '0.25rem',
            },
          },
        },
      ],
    },
    /* ==================== ICON ==================== */
    MuiIcon: {
      // TODO: Remove !important when App.css' fontFamily get fixed
      defaultProps: {
        baseClassName: 'material-icons-round',
        sx: { color: primaryBlue, fontFamily: 'Material Icons Round !important' },
      },
    },
    /* ======================== MENU ======================== */
    MuiMenu: {
      defaultProps: {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'bottom',
        },
        elevation: 1,
        transformOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      },
      variants: [
        {
          props: { variant: 'selectedMenu' },
          style: {
            [`& .${menuItemClasses.root}:hover`]: {
              backgroundColor: accentBlue,
            },
            [`& .${menuItemClasses.root}.Mui-selected`]: {
              backgroundColor: accentBlue,
            },
            [`& .${menuItemClasses.root}.Mui-selected:hover`]: {
              backgroundColor: accentBlue,
            },
            [`.${menuItemClasses.root}`]: {
              height: '40px',
            },
            [`.${listClasses.root}`]: {
              paddingBottom: 0,
              paddingTop: 0,
            },
            [`.${paperClasses.root}`]: {
              borderRadius: '8px',
              boxShadow: '0px 5px 10px rgba(29, 63, 156, 0.2)',
              marginTop: '4px',
            },
          },
        },
      ],
    },
    /* ======================== DIALOG ======================== */
    MuiDialog: {
      styleOverrides: {
        root: {
          [`& .${paperClasses.root}`]: {
            border: `1px solid ${disabledGray1}`,
            borderRadius: '8px',
            boxShadow:
              'rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px',
            fontFamily: 'Rubik',
            minWidth: '35vw',
            overflow: 'visible',
            padding: '3.5rem',
          },
          [`& .${typographyClasses.root}`]: {
            fontFamily: 'Rubik',
          },
          [`& .${dialogTitleClasses.root}`]: {
            padding: '0 0 0.1rem 0',
          },
          [`& .${dialogContentClasses.root}`]: {
            padding: '0.5rem 0',
            overflowY: 'scroll',
            [`& .${paperClasses.root}`]: {
              padding: '0rem',
            },
          },
          [`& .${dialogActionsClasses.root}`]: {
            padding: '3rem 0 0 0',
          },
        },
      },
    },
    /* ======================== PAPER ======================== */
    MuiPaper: {
      styleOverrides: {
        elevation24: {
          boxShadow: '0px 6px 12px 0px rgba(29, 63, 156, 0.25)',
          zIndex: 51,
        },
      },
    },
    /* ==================== SELECT ==================== */
    MuiSelect: {
      defaultProps: {
        defaultValue: '',
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            borderRadius: '8px',
            color: paragraphBaseline,
            fontSize: '1rem',
            height: '3rem',
            letterSpacing: '0.002em',
            lineHeight: '1.5rem',
            padding: '.75rem',
            '&.Mui-disabled': {
              backgroundColor: disabledGray1,
              [`.${selectClasses.iconOutlined}`]: {
                backgroundColor: disabledGray1,
              },
            },
            [`& .${selectClasses.select}.${inputBaseClasses.input}.${outlinedInputClasses.input}`]: {
              paddingLeft: 0,
            },
            [`.${outlinedInputClasses.notchedOutline}`]: {
              border: `1px solid ${paragraphBaseline}`,
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              border: `2px solid ${primaryBlue}`,
            },
            [`&.Mui-error .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: destructiveRed2,
            },
            [`.${selectClasses.icon}`]: {
              fill: paragraphBaseline,
              right: '1.25rem ',
              top: '1.25rem',
            },
            [`.${selectClasses.iconOutlined}`]: {
              backgroundColor: backgroundWhite,
              padding: '10px',
              top: '0.625rem',
              right: '0.625rem',
            },
            [`.${listItemIconClasses.root}`]: {
              minWidth: '2rem',
            },
            [`.${selectClasses.select}`]: {
              alignItems: 'center',
              display: 'flex',
            },
          },
        },
      ],
    },
    /* ==================== TABLE CONTAINER ==================== */
    MuiTableContainer: {
      styleOverrides: {
        root: {
          minWidth: 500,
          overflowX: 'auto',
          padding: '1rem',
          paddingTop: 0,
          width: 'auto',
          [`&.${paperClasses.root}.${tableContainerClasses.root}`]: {
            border: `1px solid ${disabledGray1}`,
            borderRadius: '8px',
            boxShadow: '0px 5px 10px rgba(29, 63, 156, 0.1)',
          },
          [`.${tableCellClasses.root}`]: {
            color: paragraphBaseline,
            fontFamily: 'Rubik',
            fontWeight: '400',
            fontSize: '14px',
            letterSpacing: '0.002em',
            lineHeight: '24px',
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          [`.${tableCellClasses.root}`]: {
            fontSize: '16px',
            textTransform: 'uppercase',
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          [`.${tableRowClasses.root}:nth-of-type(odd)`]: {
            backgroundColor: backgroundFocusBlue,
          },
          [`.${tableCellClasses.root}`]: {
            borderBottom: 0,
          },
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          [`.${paginationItemClasses.previousNext}, .${paginationItemClasses.firstLast}`]: {
            color: primaryBlue,
            margin: '0',
            width: '40px',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
          [`.${paginationItemClasses.previousNext}`]: {
            padding: '0 24px',
          },
          [`.${paginationItemClasses.page}`]: {
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: accentBlue,
            },
            '&:active': {
              backgroundColor: primaryHoverBlue1,
            },
          },
          [`.${svgIconClasses.root}.${paginationItemClasses.icon}`]: {
            width: '24px',
            height: '24px',
          },
          [`.${paginationItemClasses.root}.Mui-selected`]: {
            backgroundColor: primaryBlue,
            borderRadius: '8px',
            color: backgroundWhite,
            '&:hover, &:active': {
              backgroundColor: primaryBlue,
            },
          },
          [`.${paginationItemClasses.root}.Mui-disabled`]: {
            color: paragraphPlaceholder,
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          [`&.${tableCellClasses.root}`]: {
            border: 'none',
            width: 'max-content',
          },
          [`.${tablePaginationClasses.selectLabel}`]: {
            marginLeft: '16px',
          },
          [`.${selectClasses.select}`]: {
            borderRadius: '8px',
            padding: '8px 4px',
            '&:hover, &:focus': {
              backgroundColor: accentBlue,
            },
            '&:active': {
              backgroundColor: primaryHoverBlue1,
              borderRadius: '8px',
            },
          },
          [`.${inputBaseClasses.root}, .${tablePaginationClasses.select}`]: {
            margin: 0,
          },
          // To hide the number of rows (i.e. 5 of 8), unnecesary in our design
          [`.${tablePaginationClasses.displayedRows}`]: {
            display: 'none',
          },
          [`.${tablePaginationClasses.toolbar}`]: {
            padding: 0,
          },
        },
      },
    },
    /* ==================== TEXT FIELD ==================== */
    MuiTextField: {
      defaultProps: {},
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            backgroundColor: backgroundWhite,
            color: paragraphBaseline,
            height: '3rem',
            [`& .${outlinedInputClasses.input}`]: {
              fontSize: '1rem',
              height: '1.5rem',
              letterSpacing: '0.002em',
              padding: '.75rem',
              '&.Mui-disabled': { zIndex: 1 },
            },
            [`& .${inputLabelClasses.root}`]: {
              color: paragraphBaseline,
              fontFamily: 'Rubik',
              top: '-0.225rem',
              '&.Mui-focused': {
                color: primaryBlue,
              },
              '&.Mui-error': {
                color: destructiveRed2,
              },
              '&.Mui-disabled': {
                color: disabledGray2,
              },
            },
            [`.${outlinedInputClasses.notchedOutline}`]: {
              borderColor: paragraphBaseline,
              borderRadius: '8px',
              borderWidth: '1px',
            },
            [`.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: primaryBlue,
              borderWidth: '2px',
            },
            [`.Mui-error .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: destructiveRed2,
            },
            [`.Mui-disabled .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: disabledGray2,
              backgroundColor: disabledGray1,
            },
            [`.${formHelperTextClasses.root}`]: {
              color: paragraphBaseline,
              fontSize: '.75rem',
              fontWeight: 400,
              letterSpacing: '0.008em',
              lineHeight: '1rem',
              margin: '0.25rem 1rem 0 1rem',
              '&.Mui-error': {
                color: destructiveRed2,
              },
              '&.Mui-disabled': {
                color: disabledGray2,
              },
            },
          },
        },
        {
          props: { className: 'colorPickerInput' },
          style: {
            height: '40px',
            [` .${outlinedInputClasses.root}`]: {
              height: '40px',
            },
            [` .${outlinedInputClasses.input}`]: {
              height: '24px',
              padding: '8px 14px',
              textTransform: 'uppercase',
            },
            [` .${outlinedInputClasses.notchedOutline}`]: {
              borderRadius: '4px',
              border: `1px solid ${neutralGray}`,
              lineHeight: '1.5rem',
            },
          },
        },
      ],
    },
    /* ==================== ToggleButtonGroup ==================== */
    MuiToggleButtonGroup: {
      styleOverrides: {
        groupedVertical: {
          '&.textToolSetButton': {
            border: 'none',
            maxHeight: 47,
            '&:not(:first-of-type)': {
              borderTop: `1px solid ${neutralGray}`,
            },
          },
        },
      },
    },
    /* ==================== TYPOGRAPHY ==================== */
    MuiTypography: {
      defaultProps: {
        // The props to change the default for.
        fontFamily: 'Rubik',
        fontStyle: 'normal',
      },
      variants: [
        {
          props: { variant: 'avatarInitial' },
          style: {
            alignItems: 'center',
            backgroundColor: secondaryBlue,
            borderRadius: '50%',
            color: backgroundWhite,
            display: 'inline-flex',
            fontFamily: 'Rubik, sans-serif',
            height: '2rem',
            justifyContent: 'center',
            width: '2rem',
          },
        },
        {
          props: { variant: 'h2' },
          style: {
            color: primaryBlue,
            fontSize: '1.5rem',
            fontWeight: 600,
            lineHeight: '2rem',
            margin: '0.5rem 0 0.5rem 0',
            textAlign: 'center',
          },
        },
        {
          props: { variant: 'h3' },
          style: {
            color: paragraphBaseline,
            fontSize: '1rem',
            fontWeight: 500,
            lineHeight: '1.5rem',
            margin: '0',
            textAlign: 'left',
          },
        },
        {
          props: { variant: 'link' },
          style: {
            color: primaryBlue,
            cursor: 'pointer',
            fontSize: '0.75rem',
            letterSpacing: '0.008em',
            lineHeight: '18px',
            textAlign: 'center',
            textDecoration: 'underline',
            textDecorationColor: primaryBlue,
            textTransform: 'capitalize',
          },
        },
        {
          props: { variant: 'loginHeader' },
          style: {
            color: primaryBlue,
            fontWeight: 600,
            fontSize: '1.5rem',
            lineHeight: '32px',
            marginBottom: '8px',
            textAlign: 'center',
            textTransform: 'uppercase',
          },
        },
        {
          props: { variant: 'paragraph' },
          style: {
            color: paragraphBaseline,
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: '1.5rem',
            textAlign: 'center',
          },
        },
        {
          props: { variant: 'paragraph12' },
          style: {
            color: paragraphBaseline,
            fontWeight: 400,
            fontSize: '0.75rem',
            letterSpacing: '0.008em',
            lineHeight: '18px',
            textAlign: 'center',
          },
        },
        {
          props: { variant: 'paragraph14' },
          style: {
            color: paragraphBaseline,
            display: 'block',
            fontSize: '0.875rem',
            fontWeight: 400,
            letterSpacing: '0.002em',
            lineHeight: '24px',
            textAlign: 'left',
          },
        },
        {
          props: { variant: 'viewHeader' },
          style: {
            color: paragraphBaseline,
            display: 'inline-block',
            fontWeight: 600,
            fontSize: '1.5rem',
            lineHeight: '32px',
            marginTop: '24px',
          },
        },
        {
          props: { variant: 'canvasTitle' },
          style: {
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '32px',
            '&[contenteditable]:empty::before': {
              content: '"Enter your title"',
              color: disabledGray2,
            },
          },
        },
        {
          props: { variant: 'canvasSubtitle' },
          style: {
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.004rem',
            '&[contenteditable]:empty::before': {
              content: '"Enter your subtitle"',
              color: disabledGray2,
            },
          },
        },
        {
          props: { variant: 'textColorTool' },
          style: {
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '24px',
          },
        },
        {
          props: { variant: 'textSizeTool' },
          style: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',
          },
        },
        {
          props: { variant: 'colorPickerInputLabel' },
          style: {
            color: black,
            fontSize: '0.75rem',
            fontWeight: 700,
            lineHeight: '16px',
            letterSpacing: '0.006em',
          },
        },
      ],
    },
  },
  palette: {
    accentBlue: {
      main: accentBlue,
      contrastText: primaryBlue,
    },
    primaryHoverBlue1: {
      main: primaryHoverBlue1,
      contrastText: primaryBlue,
    },
  },
});
