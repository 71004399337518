import { ColorPicker } from 'components/colorPicker/ColorPicker';
import DraggableTextBox from 'components/resizableAndDraggable/DraggableTextBox';
import { TextToolSet } from 'components/textToolSet/TextToolSet';
import { Dispatch, FC, MouseEvent, SetStateAction, useEffect, useMemo, useState } from 'react';
import { neutralGray3 } from 'styles/partials/variables';
import { Background } from 'type/background';
import { TextColors, TextBoxType, TextCoordinates } from 'type/textBox';
import { TextBox, TextToolSetOptions } from 'utils/constants/canvas';
import { useAppSelector } from 'utils/hooks/storeHooks';
import { Box } from '@mui/material';
import styles from '../../views/backgroundForm/BackgroundForm.module.scss';

type CanvasProps = {
  children: React.ReactNode;
  chosenTextColors: ({ title, subtitle }: TextColors) => void;
  chosenTitleCoordinates: ({ x, y }: TextCoordinates) => void;
  chosenSubtitleCoordinates: ({ x, y }: TextCoordinates) => void;
  customBackgroundImgUrl?: string;
  height?: number;
  hideSubtitleBox: boolean;
  hideTitleBox: boolean;
  setShowTextTools: Dispatch<SetStateAction<boolean>>;
  showTextTools: boolean;
  width?: number;
  onTextDelete: (hide: boolean, isTitle?: boolean) => void;
};

const MAX_HEIGHT = 424;
const MAX_WIDTH = 792;

const Canvas: FC<CanvasProps> = ({
  children,
  chosenTextColors,
  chosenTitleCoordinates,
  chosenSubtitleCoordinates,
  customBackgroundImgUrl,
  height = MAX_HEIGHT,
  hideSubtitleBox,
  hideTitleBox,
  setShowTextTools,
  showTextTools,
  width = MAX_WIDTH,
  onTextDelete,
}: CanvasProps) => {
  const [textColor, setTextColor] = useState({
    title: neutralGray3,
    subtitle: neutralGray3,
  });
  const [focusedTextType, setFocusedTextType] = useState<TextBoxType>(TextBox.Title);
  const [textToolSelected, setTextToolSelected] = useState<TextToolSetOptions>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openColorPicker = Boolean(anchorEl);
  const backgroundSelected = useAppSelector<Background | null | undefined>(
    (state) => state.background.backgroundSelected,
  );

  useMemo(() => {
    if (backgroundSelected) {
      const { draftData } = backgroundSelected;
      if (draftData) {
        const { titleBox, subtitleBox } = draftData;
        if (titleBox?.textColor && subtitleBox?.textColor) {
          setTextColor({
            title: titleBox?.textColor,
            subtitle: subtitleBox?.textColor,
          });
        }
      }
    }
  }, [backgroundSelected]);

  const handleRemoveTextToolSet = () => {
    if (!openColorPicker) {
      setShowTextTools(false);
    }
  };

  const handleClickTextColorTool = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget.parentElement);
  };

  const handleCloseColorPicker = () => {
    setAnchorEl(null);
  };

  const handleChangeTextTool = (_event: MouseEvent<HTMLElement>, value: TextToolSetOptions) => {
    setTextToolSelected(value);
  };

  const handleOnDeleteTextBox = (textType: TextBoxType) => {
    onTextDelete(true, textType === TextBox.Title);
    if (focusedTextType === textType && showTextTools) {
      handleRemoveTextToolSet();
    }
  };

  const handleShowTextTools = (textType: TextBoxType) => {
    setShowTextTools(true);
    setFocusedTextType(textType);
  };

  const handleChangeColorToolSet = (color: string) => {
    setTextColor((prev) => ({ ...prev, [focusedTextType]: color }));
  };

  useEffect(() => {
    if (textColor) chosenTextColors(textColor);
  }, [chosenTextColors, textColor]);

  return (
    <Box
      className={styles.canvas}
      id='canvas'
      sx={{
        backgroundImage: `url(${customBackgroundImgUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        width,
        height,
      }}
      onClick={handleRemoveTextToolSet}
      onMouseLeave={handleRemoveTextToolSet}
    >
      {/* Text color picker */}
      <ColorPicker
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        color={textColor[focusedTextType]}
        open={openColorPicker}
        paperProps={{ sx: { margin: 0, marginLeft: '8px' } }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onChange={handleChangeColorToolSet}
        onClose={handleCloseColorPicker}
      />
      {showTextTools && (
        <TextToolSet
          textColor={textColor[focusedTextType]}
          textTool={textToolSelected}
          onChangeTextTool={handleChangeTextTool}
          onClickTextColorTool={handleClickTextColorTool}
        />
      )}
      {!hideTitleBox && (
        <DraggableTextBox
          activeColorEditing={focusedTextType === TextBox.Title && showTextTools}
          textColor={textColor.title}
          textCoordinates={(newCoords) => chosenTitleCoordinates(newCoords)}
          textId={TextBox.Title}
          onClickTextBox={() => handleShowTextTools(TextBox.Title)}
          onDelete={() => handleOnDeleteTextBox(TextBox.Title)}
        />
      )}
      {!hideSubtitleBox && (
        <DraggableTextBox
          activeColorEditing={focusedTextType === TextBox.Subtitle && showTextTools}
          textColor={textColor.subtitle}
          textCoordinates={(newCoords) => chosenSubtitleCoordinates(newCoords)}
          textId={TextBox.Subtitle}
          onClickTextBox={() => handleShowTextTools(TextBox.Subtitle)}
          onDelete={() => handleOnDeleteTextBox(TextBox.Subtitle)}
        />
      )}
      {children}
    </Box>
  );
};

export default Canvas;
