/* eslint-disable no-continue */

/* eslint-disable no-await-in-loop */

/* eslint-disable no-restricted-syntax */
import { cloneDeep } from 'lodash';
import { ImgProps } from 'type/canvas';
import { BackgroundFile } from 'type/file';
import { BaseService } from './baseService';

class FileService extends BaseService<BackgroundFile> {
  controllerName = 'file';

  async createNewFile(fileName: string) {
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.post(
      `${this.controllerName}`,
      JSON.stringify({
        type: 'background',
        fileName,
      }),
    );
    return data;
  }

  // Tainted canvas bug solution
  async getFileByS3Id(id: number) {
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.get(`${this.controllerName}/${id}/image`);
    return data;
  }

  async uploadFile(file: File, fileName: string) {
    const { data: newFile } = await this.createNewFile(fileName);
    await fetch(newFile.uploadUrl as string, {
      method: 'PUT',
      body: file,
    });
    return newFile;
  }
}

export const fileService = new FileService();

export const createFileFromUrl = async (url: string, name: string, defaultType = 'image/jpeg') => {
  const response = await fetch(url);
  const data = await response.blob();
  const newFileFromURL = new File([data], `${name}.jpeg`, {
    type: defaultType,
  });
  return newFileFromURL;
};

export const uploadDraftImagesToS3 = async (draftImages: ImgProps[]) => {
  const addedS3IdImagesArray: ImgProps[] = [];
  // convert each image into  a file to upload into the s3 bucket and retrieve their s3 img id
  for (const img of draftImages) {
    const { image: imgUrl, id: imgFileName } = img;
    const customImgFile = await createFileFromUrl(imgUrl, imgFileName);
    if (!customImgFile) {
      addedS3IdImagesArray.push(img);
      continue;
    }
    try {
      const newFileId = img.idS3 || (await fileService.uploadFile(customImgFile, customImgFile.name)).id; // only loads new img if it not exists already in s3
      const {
        data: { id },
      } = await fileService.getById(Number(newFileId));
      img.idS3 = id;
    } catch (error) {
      console.error(error);
    }
    addedS3IdImagesArray.push(img);
  }
  return addedS3IdImagesArray;
};

export const downloadImagesFromS3 = async (draftImages: ImgProps[]) => {
  const modifiedUrlImgArray: ImgProps[] = [];
  // We'll use the id of each image to fetch a fresh s3 url to access it (these urls expire after 10 min)
  for (const img of draftImages) {
    const { idS3 } = img;
    const imgDeepCopy = cloneDeep(img);
    if (idS3) {
      try {
        const {
          data: { url },
        } = await fileService.getById(idS3);
        imgDeepCopy.urlS3 = url; // this url cant be directly used in image because it taints the canvas
        imgDeepCopy.image = await fileService.getFileByS3Id(idS3); // instead we use the image brought through the BE
      } catch (error) {
        console.error(error);
      }
    }
    modifiedUrlImgArray.push(imgDeepCopy);
  }
  return modifiedUrlImgArray;
};
