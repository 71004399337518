import { Dayjs } from 'dayjs';
import { ServiceOptions } from 'services/baseService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { announcementService } from '../../services/announcementsService';
import { audienceAnnouncementService } from '../../services/audienceAnnouncementService';
import { Announcement, AudienceAnnouncement } from '../../type/announcement';

type AnnouncementState = {
  announcements: Announcement[];
  groupAnnouncements: Announcement[];
  individualAnnouncements: Announcement[];
  announcementSelected?: Announcement | null;
  loading: boolean;
  error?: string | unknown;
};

const initialState: AnnouncementState = {
  announcements: [],
  groupAnnouncements: [],
  individualAnnouncements: [],
  loading: false,
};

export const postNewAnnouncement = createAsyncThunk<
  Announcement,
  {
    audience: number[] | string[];
    name: string;
    categoryId?: number;
    date?: Dayjs;
    description?: string;
    duration?: number;
    eventTime?: string;
    eventTimeZone?: string;
    iconReference?: string;
    link?: string;
    status?: string;
    time?: string;
    timezone?: string;
  }
>(
  'announcement/postNewAnnouncement',
  async (
    req: {
      audience: number[] | string[];
      name: string;
      categoryId?: number;
      date?: Dayjs;
      description?: string;
      duration?: number;
      eventTime?: string;
      eventTimeZone?: string;
      iconReference?: string;
      link?: string;
      status?: string;
      time?: string;
      timezone?: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const {
        categoryId,
        date,
        description,
        duration,
        eventTime,
        eventTimeZone,
        iconReference,
        link,
        name,
        time,
        timezone,
        status,
      } = req;
      const { data: newAnnouncement } = await announcementService.create({
        categoryId: categoryId || 1,
        description,
        event_time: eventTime,
        event_time_zone: eventTimeZone,
        icon_reference: iconReference,
        name,
        link,
        date: date?.format('YYYY-MM-DD'),
        duration,
        status,
        time,
        time_zone: timezone,
      });

      await Promise.all(
        req.audience.map(async (audience) =>
          audienceAnnouncementService.createNewAudienceAnnouncement(newAnnouncement.id, audience),
        ),
      );

      const options: ServiceOptions = {
        include: [{ AudienceAnnouncement: ['Group'] }, 'Category'],
      };
      const { data: newAnnouncementData } = await announcementService.getById(newAnnouncement.id, options);

      return newAnnouncementData;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const readAllAnnouncements = createAsyncThunk<Announcement[]>(
  'announcement/readAllAnnouncements',
  async (_: void, { rejectWithValue }) => {
    try {
      const options: ServiceOptions = {
        include: [{ AudienceAnnouncement: ['Group'] }, 'Category'],
        where: { is_active: true },
        order: [['date', 'ASC']],
      };
      const { data } = await announcementService.getAll(options);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const readAnnouncementById = createAsyncThunk<Announcement, number>(
  'announcement/readAnnouncementById',
  async (announcementId: number, { rejectWithValue }) => {
    try {
      const options: ServiceOptions = {
        include: [{ AudienceAnnouncement: ['Group'] }, 'Category'],
      };
      const { data } = await announcementService.getById(announcementId, options);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const removeAnnouncementById = createAsyncThunk<number, number>(
  'announcement/removeAnnouncementById',
  async (announcementId: number, { rejectWithValue }) => {
    try {
      await announcementService.delete(announcementId);

      return announcementId;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateAnnouncement = createAsyncThunk<
  Announcement,
  {
    audience: number[] | string[];
    currentAudience: AudienceAnnouncement[];
    id: number;
    name: string;
    categoryId?: number;
    date?: Dayjs;
    description?: string;
    duration?: number;
    eventTime?: string;
    eventTimeZone?: string;
    iconReference?: string;
    link?: string;
    status?: string;
    time?: string;
    timezone?: string;
  }
>(
  'announcement/updateAnnouncement',
  async (
    req: {
      audience: number[] | string[];
      currentAudience: AudienceAnnouncement[];
      id: number;
      name: string;
      categoryId?: number;
      date?: Dayjs;
      description?: string;
      duration?: number;
      eventTime?: string;
      eventTimeZone?: string;
      iconReference?: string;
      link?: string;
      status?: string;
      time?: string;
      timezone?: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const {
        categoryId,
        currentAudience,
        date,
        duration,
        description,
        eventTime,
        eventTimeZone,
        iconReference,
        link,
        name,
        status,
        time,
        timezone,
      } = req;
      await announcementService.patch(req.id, {
        categoryId: categoryId || 1,
        date: date?.format('YYYY-MM-DD'),
        description,
        duration,
        event_time: eventTime,
        event_time_zone: eventTimeZone,
        icon_reference: iconReference,
        link,
        name,
        status,
        time,
        time_zone: timezone,
      });

      let audiencesToAdd = [...req.audience];
      const byGroup = typeof req.audience[0] === 'number';
      await Promise.all(
        currentAudience.map(async ({ fullName, groupId, id }) => {
          const isAudienceNotIncluded = !req.audience.join(', ').includes(groupId ? groupId.toString() : fullName);
          if (isAudienceNotIncluded) {
            return audienceAnnouncementService.delete(id);
          }
          audiencesToAdd = audiencesToAdd.filter((audience) => audience !== (byGroup ? groupId : fullName));
          return false;
        }),
      );

      await Promise.all(
        audiencesToAdd.map(async (audience) =>
          audienceAnnouncementService.createNewAudienceAnnouncement(req.id, audience),
        ),
      );

      const options: ServiceOptions = {
        include: [{ AudienceAnnouncement: ['Group'] }, 'Category'],
      };
      const { data: updatedAnnouncement } = await announcementService.getById(req.id, options);

      return updatedAnnouncement;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const announcementSlice = createSlice({
  name: 'announcement',
  initialState,

  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    // ========================== CREATE NEW ANNOUNCEMENT ==========================
    // When our request is pending:
    builder.addCase(postNewAnnouncement.pending, (state) => {
      state.loading = true;
    });
    // When our request is fulfilled:
    builder.addCase(postNewAnnouncement.fulfilled, (state, action) => {
      state.announcements.push(action.payload);
      state.loading = false;
    });
    // When our request is rejected:
    builder.addCase(postNewAnnouncement.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    // ========================== READ ALL ANNOUCEMENTS ==========================
    // When our request is pending:
    builder.addCase(readAllAnnouncements.pending, (state) => {
      state.loading = true;
    });
    // When our request is fulfilled:
    builder.addCase(readAllAnnouncements.fulfilled, (state, action) => {
      state.announcements = action.payload;
      const groupAnn: Announcement[] = [];
      const individualAnn: Announcement[] = [];
      action.payload.forEach((announcement) => {
        if (announcement.AudienceAnnouncement.length > 0) {
          if (announcement.AudienceAnnouncement[0].groupId) {
            groupAnn.push(announcement);
          } else {
            individualAnn.push(announcement);
          }
        }
      });
      state.groupAnnouncements = groupAnn;
      state.individualAnnouncements = individualAnn;
      state.announcementSelected = null;
      state.loading = false;
    });
    // When our request is rejected:
    builder.addCase(readAllAnnouncements.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    // ========================== READ ANNOUNCEMENT BY ID ==========================
    // When our request is pending:
    builder.addCase(readAnnouncementById.pending, (state) => {
      state.loading = true;
    });
    // When our request is fulfilled:
    builder.addCase(readAnnouncementById.fulfilled, (state, action) => {
      state.announcementSelected = action.payload;
      state.loading = false;
    });
    // When our request is rejected:
    builder.addCase(readAnnouncementById.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    // ========================== DELETE ANNOUNCEMENT ==========================
    // When our request is pending:
    builder.addCase(removeAnnouncementById.pending, (state) => {
      state.loading = true;
    });
    // When our request is fulfilled:
    builder.addCase(removeAnnouncementById.fulfilled, (state, action) => {
      state.announcements = state.announcements.filter((announcement) => announcement.id !== action.payload);
      state.groupAnnouncements = state.groupAnnouncements.filter((announcement) => announcement.id !== action.payload);
      state.individualAnnouncements = state.individualAnnouncements.filter(
        (announcement) => announcement.id !== action.payload,
      );
      state.loading = false;
    });
    // When our request is rejected:
    builder.addCase(removeAnnouncementById.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    // ========================== UPDATE ANNOUNCEMENT ==========================
    // When our request is pending:
    builder.addCase(updateAnnouncement.pending, (state) => {
      state.loading = true;
    });
    // When our request is fulfilled:
    builder.addCase(updateAnnouncement.fulfilled, (state, action) => {
      state.announcements = state.announcements.map((announcement) => {
        if (announcement.id === action.payload.id) {
          return action.payload;
        }

        return announcement;
      });
      state.loading = false;
    });
    // When our request is rejected:
    builder.addCase(updateAnnouncement.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export default announcementSlice.reducer;
