/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { Stage, Layer } from 'react-konva';
import { ImgProps, ImgArrProps } from 'type/canvas';
import DraggableImage from './DraggableImage';

const DragAndResizeImages = ({ draftImagesArray, imagesToRender, imageCount, resetImagesArray }: ImgArrProps) => {
  const [images, setImages] = useState<ImgProps[] | []>(imagesToRender || []);
  const [selectedImageId, setSelectImageId] = useState<string | null>(null);
  const hasNewImage: boolean = imagesToRender.length > 0;
  const MAX_HEIGHT = 424;
  const MAX_WIDTH = 792;
  //   Do NOT add 'images' to dependecies array or it throws a "too many re-renders" error, leave comment on line 102
  useMemo(() => {
    const newImages = imagesToRender.filter((image) => !images.find(({ id }) => image.id === id));
    if (newImages) setImages((prevImages) => [...prevImages, ...newImages]);
    resetImagesArray();
  }, [hasNewImage]);

  useEffect(() => {
    draftImagesArray(images);
  }, [images]);

  // Filters to remove images from the array, deletes the one that matches the specified id
  const handleDelete = (idToDelete: string) => {
    setImages((prevArr) => prevArr.filter((img) => img.id !== idToDelete));
  };
  imageCount(images.length);

  return (
    // The canvas aree where the images array can move around
    <Stage
      height={MAX_HEIGHT}
      width={MAX_WIDTH}
      onMouseDown={(e) => {
        const clickedOnEmpty = e.target === e.target.getStage();
        if (clickedOnEmpty) {
          setSelectImageId(null);
        }
      }}
    >
      <Layer>
        {images.length > 0 &&
          images.map((image, index) => (
            <DraggableImage
              draggableImgProps={image}
              image={image.image}
              isSelected={image.id === selectedImageId}
              key={image.id}
              onChange={(newAttrs: ImgProps) => {
                // Detects any change on the images array
                const imgs = images.slice();
                imgs[index] = newAttrs;
                setImages(imgs);
              }}
              onDelete={() => {
                handleDelete(image.id);
              }}
              onSelect={() => {
                setSelectImageId(image.id);
              }}
            />
          ))}
      </Layer>
    </Stage>
  );
};

export default DragAndResizeImages;
