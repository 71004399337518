import AzulCheckbox from 'components/simpleComponents/AzulCheckbox';
import AzulDatePicker from 'components/simpleComponents/AzulDatePicker';
import { azulDesign } from 'components/simpleComponents/AzulDesignTheme';
import AzulModal from 'components/simpleComponents/AzulModal';
import AzulSelect from 'components/simpleComponents/AzulSelect';
import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { readAllGroups } from 'store/features/groupSlice';
import { readAllUsers } from 'store/features/userSlice';
import { Group } from 'type/group';
import { SynkronUser } from 'type/user';
import { getAudienceOptions } from 'utils/constants/audienceType';
import { useAppDispatch, useAppSelector } from 'utils/hooks/storeHooks';
import { Box, Button, Grid, SelectChangeEvent, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

type AnnouncementFiltersModalProps = {
  audienceFilters: (audience: string[]) => void;
  categoriesFilters: (status: {
    'Area Info Related': boolean;
    Community: boolean;
    Training: boolean;
    Activities: boolean;
    Social: boolean;
    'For All': boolean;
  }) => void;
  dateFilters: (dateRange: number[]) => void;
  isAnnouncementByGroup: boolean;
  open: boolean;
  statusFilters: (status: { published: boolean; scheduled: boolean; draft: boolean }) => void;
  onClose: () => void;
};

const AnnouncementFiltersModal = ({
  audienceFilters,
  categoriesFilters,
  dateFilters,
  isAnnouncementByGroup,
  open,
  statusFilters,
  onClose,
}: AnnouncementFiltersModalProps) => {
  const dispatch = useAppDispatch();
  const availableAudience: Array<Group | SynkronUser> = useAppSelector((state) =>
    isAnnouncementByGroup ? state.group.groups : state.user.users,
  );
  const audienceOptions = getAudienceOptions(availableAudience, isAnnouncementByGroup);

  /* ===================================== FILTERS STATES ===================================== */
  const [startDateFilter, setStartDateFilter] = useState<Dayjs | null>(null);
  const [finishDateFilter, setFinishDateFilter] = useState<Dayjs | null>(null);
  const [audienceFilterArr, setAudienceFilterArr] = useState<string[]>([]);
  const [isActivitiesChecked, setIsActivitiesChecked] = useState<boolean>(false);
  const [isAreaInfoRelatedChecked, setIsAreaInfoRelatedChecked] = useState<boolean>(false);
  const [isCommunityChecked, setIsCommunityChecked] = useState<boolean>(false);
  const [isDraftChecked, setIsDraftChecked] = useState<boolean>(false);
  const [isForAllChecked, setIsForAllChecked] = useState<boolean>(false);
  const [isPublishedChecked, setIsPublishedChecked] = useState<boolean>(false);
  const [isScheduledChecked, setIsScheduledChecked] = useState<boolean>(false);
  const [isSocialChecked, setIsSocialChecked] = useState<boolean>(false);
  const [isTrainingChecked, setIsTrainingChecked] = useState<boolean>(false);

  /* ===================================== HANDLERS ===================================== */
  const handleAudienceFilterChange = (event: SelectChangeEvent<string | string[]>) => {
    const { value } = event.target;
    setAudienceFilterArr(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(', ') : value,
    );
  };

  const handleFinishDateChange = (newDate: Dayjs | null) => {
    if (newDate) {
      setFinishDateFilter(newDate);
    }
  };

  const handleStartDateChange = (newDate: Dayjs | null) => {
    if (newDate) {
      setStartDateFilter(newDate);
    }
  };

  // Function that triggers callbacks with the values to filter by on categories, audience, date and status
  const handleApplyFilters = () => {
    categoriesFilters({
      'Area Info Related': isAreaInfoRelatedChecked,
      Community: isCommunityChecked,
      Training: isTrainingChecked,
      Activities: isActivitiesChecked,
      Social: isSocialChecked,
      'For All': isForAllChecked,
    });
    audienceFilters(audienceFilterArr);
    if (startDateFilter && finishDateFilter) {
      const startDateInMiliseconds = startDateFilter?.toDate().getTime();
      const endDateInMiliseconds = finishDateFilter?.toDate().getTime();
      dateFilters([startDateInMiliseconds, endDateInMiliseconds]);
    } else {
      dateFilters([]);
    }
    statusFilters({
      published: isPublishedChecked,
      scheduled: isScheduledChecked,
      draft: isDraftChecked,
    });
    onClose();
  };

  const handleResetFilters = () => {
    setAudienceFilterArr([]);
    setFinishDateFilter(null);
    setIsActivitiesChecked(false);
    setIsAreaInfoRelatedChecked(false);
    setIsCommunityChecked(false);
    setIsDraftChecked(false);
    setIsForAllChecked(false);
    setIsPublishedChecked(false);
    setIsScheduledChecked(false);
    setIsSocialChecked(false);
    setIsTrainingChecked(false);
    setStartDateFilter(null);
  };

  /* ===================================== USE EFFECTS ===================================== */
  useEffect(() => {
    if (isAnnouncementByGroup) {
      dispatch(readAllGroups());
    } else dispatch(readAllUsers());
  }, [dispatch, isAnnouncementByGroup]);

  return (
    <AzulModal
      modalActions={
        <ThemeProvider theme={azulDesign}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Button variant='azulBackLink' onClick={handleResetFilters}>
              Reset Filter
            </Button>

            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <Button variant='azulSecondary' onClick={onClose}>
                Cancel
              </Button>

              <Button variant='azulPrimary' onClick={handleApplyFilters}>
                Apply
              </Button>
            </Box>
          </Box>
        </ThemeProvider>
      }
      modalContent={
        <ThemeProvider theme={azulDesign}>
          <Typography variant='h3'>Category</Typography>

          <Box sx={{ padding: '1rem 1rem 2rem 1rem' }}>
            <Grid container spacing={2}>
              <Grid display='flex' item xs={4}>
                <AzulCheckbox checked={isForAllChecked} onClick={() => setIsForAllChecked(!isForAllChecked)} />
                <Typography sx={{ marginLeft: '1rem' }}>For All</Typography>
              </Grid>

              <Grid display='flex' item xs={4}>
                <AzulCheckbox
                  checked={isAreaInfoRelatedChecked}
                  onClick={() => setIsAreaInfoRelatedChecked(!isAreaInfoRelatedChecked)}
                />
                <Typography sx={{ marginLeft: '1rem' }}>Area Info Related</Typography>
              </Grid>

              <Grid display='flex' item xs={4}>
                <AzulCheckbox checked={isCommunityChecked} onClick={() => setIsCommunityChecked(!isCommunityChecked)} />
                <Typography sx={{ marginLeft: '1rem' }}>Community</Typography>
              </Grid>

              <Grid display='flex' item xs={4}>
                <AzulCheckbox checked={isTrainingChecked} onClick={() => setIsTrainingChecked(!isTrainingChecked)} />
                <Typography sx={{ marginLeft: '1rem' }}>Training</Typography>
              </Grid>

              <Grid display='flex' item xs={4}>
                <AzulCheckbox
                  checked={isActivitiesChecked}
                  onClick={() => setIsActivitiesChecked(!isActivitiesChecked)}
                />
                <Typography sx={{ marginLeft: '1rem' }}>Activities</Typography>
              </Grid>

              <Grid display='flex' item xs={4}>
                <AzulCheckbox checked={isSocialChecked} onClick={() => setIsSocialChecked(!isSocialChecked)} />
                <Typography sx={{ marginLeft: '1rem' }}>Social</Typography>
              </Grid>
            </Grid>
          </Box>

          <Typography variant='h3'>Audience</Typography>

          <Box sx={{ padding: '1rem 0 2rem 0' }}>
            <AzulSelect
              id='audience-filter-select'
              label='Select Audience'
              labelId='audience-filter-select-label'
              multiple
              options={audienceOptions}
              searchBar
              value={audienceFilterArr}
              width='100%'
              onChange={handleAudienceFilterChange}
            />
          </Box>

          <Typography variant='h3'>Date Range</Typography>

          <Box sx={{ padding: '1rem 0 2rem 0' }}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <AzulDatePicker
                  label='Start Date'
                  value={startDateFilter}
                  width='100%'
                  onChange={handleStartDateChange}
                />
              </Grid>

              <Grid item xs={6}>
                <AzulDatePicker
                  label='Finish Date'
                  value={finishDateFilter}
                  width='100%'
                  onChange={handleFinishDateChange}
                />
              </Grid>
            </Grid>
          </Box>

          <Typography variant='h3'>Status</Typography>

          <Box sx={{ padding: '1rem 1rem 0 1rem' }}>
            <Grid container spacing={2}>
              <Grid display='flex' item xs={4}>
                <AzulCheckbox checked={isPublishedChecked} onClick={() => setIsPublishedChecked(!isPublishedChecked)} />
                <Typography sx={{ marginLeft: '1rem' }}>Published</Typography>
              </Grid>

              <Grid display='flex' item xs={4}>
                <AzulCheckbox checked={isScheduledChecked} onClick={() => setIsScheduledChecked(!isScheduledChecked)} />
                <Typography sx={{ marginLeft: '1rem' }}>Scheduled</Typography>
              </Grid>

              <Grid display='flex' item xs={4}>
                <AzulCheckbox checked={isDraftChecked} onClick={() => setIsDraftChecked(!isDraftChecked)} />
                <Typography sx={{ marginLeft: '1rem' }}>Draft</Typography>
              </Grid>
            </Grid>
          </Box>
        </ThemeProvider>
      }
      modalContentPadding='3rem 0'
      open={open}
      title='Filters'
      onClose={onClose}
    />
  );
};

export default AnnouncementFiltersModal;
