import AzulConfirmNavigationModal from 'components/simpleComponents/AzulConfirmNavigationModal';
import dayjs, { Dayjs } from 'dayjs';
import { FC, Fragment, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorPayload } from 'type/data';
import { CATEGORIES, getCategoryLabel } from 'utils/constants/announcementCategories';
import { Box, Button, SelectChangeEvent, ThemeProvider, Typography } from '@mui/material';
import { ReactComponent as BackIcon } from '../../assets/BackArrow.svg';
import { ReactComponent as ExclamationMark } from '../../assets/ExclamationMark.svg';
import AzulDatePicker from '../../components/simpleComponents/AzulDatePicker';
import { azulDesign } from '../../components/simpleComponents/AzulDesignTheme';
import AzulInput from '../../components/simpleComponents/AzulInput';
import AzulSelect from '../../components/simpleComponents/AzulSelect';
import AzulTimePicker from '../../components/simpleComponents/AzulTimePicker';
import SimpleSnackbar from '../../components/snackbar/Snackbar';
import { postNewAnnouncement, readAnnouncementById, updateAnnouncement } from '../../store/features/announcementSlice';
import snackContext from '../../store/features/contextSnackbar';
import { readAllGroups } from '../../store/features/groupSlice';
import { readAllUsers } from '../../store/features/userSlice';
import { paragraphBaseline, primaryBlue } from '../../styles/partials/variables';
import { Group } from '../../type/group';
import { SynkronUser } from '../../type/user';
import { AudienceType } from '../../utils/constants/audienceType';
import { DURATIONS, TIMEZONES } from '../../utils/constants/durationsAndTimezones';
import { PublishingStatus } from '../../utils/constants/publishingStatus';
import { Services } from '../../utils/constants/services';
import { snakeCaseToPascalCase } from '../../utils/formatters/text';
import { useAppDispatch, useAppSelector } from '../../utils/hooks/storeHooks';
import { isValidHttpUrl } from '../../utils/validators/url';
import styles from './AnnouncementForm.module.scss';
import muiRoundedIcons from './muiRoundedIcons';

const MAX_NAME_LENGTH = 50;
const MAX_DESCRIPTION_LENGTH = 256;

type AnnouncementFormProps = {
  audienceType: AudienceType.Group | AudienceType.Individual; // Handles if the service is for Group or Individual Announcements
  service?: 'create' | 'edit'; // The operation to use with the form info
};

const AnnouncementForm: FC<AnnouncementFormProps> = ({ audienceType, service = 'create' }: AnnouncementFormProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // Hook to grab the path's params that we're interested on, in this case the id of the group to edit
  const params = useParams();
  const today = dayjs();
  const { setSnackPack } = useContext(snackContext);
  const isAnnouncementByGroup = audienceType === AudienceType.Group;
  const announcementId = Number(params.id);
  const announcementSelected = useAppSelector((state) => state.announcement.announcementSelected);
  const availableAudience: Array<Group | SynkronUser> = useAppSelector((state) =>
    isAnnouncementByGroup ? state.group.groups : state.user.users,
  );
  const currentAudience = announcementSelected ? announcementSelected.AudienceAnnouncement : [];

  /* ========================================= STATES ========================================= */
  const [selectedIcon, setSelectedIcon] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [selectedEventHour, setSelectedEventHour] = useState<Dayjs | null>(null);
  const [selectedEventTimezone, setSelectedEventTimezone] = useState<string>('');
  const [selectedAudienceArr, setSelectedAudienceArr] = useState<string[]>([]);
  const [selectedAudienceIdsArr, setSelectedAudienceIdsArr] = useState<number[]>([]);
  const [redirectionLink, setRedirectionLink] = useState<string>('');
  const [isRedirectionLinkValid, setIsRedirectionLinkValid] = useState<boolean>(true);
  const [selectedDuration, setSelectedDuration] = useState<string>('');
  const [isEditDurationValid, setIsEditDurationValid] = useState<boolean>(true);
  const [publishingDate, setPublishingDate] = useState<Dayjs | null>(null);
  const [selectedPublishingTimezone, setSelectedPublishingTimezone] = useState<string>('');
  const [selectedPublishingHour, setSelectedPublishingHour] = useState<Dayjs | null>(null);
  const [isNameInvalid, setIsNameInvalid] = useState<boolean>(false);
  const [isEventTimeInvalid, setIsEventTimeInvalid] = useState<boolean>(false);
  const [isDateInvalid, setIsDateInvalid] = useState<boolean>(false);
  const [isPublishingTimeInvalid, setIsPublishingTimeInvalid] = useState<boolean>(false);
  const [isSaveDraftDisabled, setIsSaveDraftDisabled] = useState<boolean>(true);
  const [isPublishDisabled, setIsPublishDisabled] = useState<boolean>(true);
  const [isSaveChangesDisabled, setIsSaveChangesDisabled] = useState<boolean>(true);
  const [isConfirmNavigationModalOpen, setIsConfirmNavigationModalOpen] = useState<boolean>(false);

  /* ======================================= VARIABLES ======================================= */
  const isSaveDraftDisabledValidation =
    selectedAudienceArr.length < 1 ||
    name.length < 1 ||
    isNameInvalid ||
    isEventTimeInvalid ||
    isDateInvalid ||
    isPublishingTimeInvalid ||
    !isRedirectionLinkValid ||
    (selectedEventHour && !selectedEventTimezone) ||
    false;
  const isPublishDisabledValidation =
    isNameInvalid ||
    isEventTimeInvalid ||
    isDateInvalid ||
    isPublishingTimeInvalid ||
    !isRedirectionLinkValid ||
    selectedIcon === '' ||
    name.length < 1 ||
    !category ||
    (selectedEventHour && !selectedEventTimezone) ||
    selectedDuration === '' ||
    !selectedAudienceArr.length ||
    !publishingDate ||
    !selectedPublishingHour ||
    selectedPublishingTimezone === '';
  const isAnnouncementPublished = announcementSelected?.status === PublishingStatus.Published;
  const newDateTime = dayjs(String(selectedPublishingHour)).format('hh:mm a');
  const publishingDateTimePreview = `This announcement will be published on ${
    publishingDate?.isValid() && publishingDate > today.subtract(1, 'day')
      ? publishingDate.format('MM/DD/YYYY')
      : '[date]'
  } at  ${newDateTime !== 'Invalid Date' ? newDateTime : '[time]'} ${selectedPublishingTimezone || '[timezone]'}`;

  const iconOptions = muiRoundedIcons.map((value) => ({
    icon: value,
    label: snakeCaseToPascalCase(value),
    value,
  }));
  const audienceOptions = availableAudience.map((audience) => {
    const value = isAnnouncementByGroup ? (audience as Group).name : (audience as SynkronUser).fullName;
    return {
      label: value,
      value,
    };
  });

  /* ========================================= HANDLERS ========================================= */
  // Handler for Publish create and edit serivces
  const handlePublish = async () => {
    let isSnackSuccessful = false;
    let snackMsg = 'Successful operation';
    let snackErrorMsg = 'There has been an error';

    if (service === Services.Create && publishingDate) {
      const res = await dispatch(
        postNewAnnouncement({
          audience: isAnnouncementByGroup ? selectedAudienceIdsArr : selectedAudienceArr,
          categoryId: parseInt(category, 10),
          date: publishingDate,
          description,
          duration: Number(selectedDuration),
          /* TODO: Make this values optional */
          eventTime: selectedEventHour ? `${dayjs(String(selectedEventHour)).format('HH:mm')}:00` : undefined,
          eventTimeZone: selectedEventTimezone || undefined,
          iconReference: selectedIcon,
          link: redirectionLink || undefined,
          name,
          time: `${dayjs(String(selectedPublishingHour)).format('HH:mm')}:00`,
          timezone: selectedPublishingTimezone,
        }),
      );
      isSnackSuccessful = res.meta.requestStatus === 'fulfilled';
      snackMsg = 'The announcement has been created';
      if (!isSnackSuccessful) snackErrorMsg = (res.payload as ErrorPayload).response.data.data.error;
    } else if (publishingDate) {
      const res = await dispatch(
        updateAnnouncement({
          audience: isAnnouncementByGroup ? selectedAudienceIdsArr : selectedAudienceArr,
          categoryId: parseInt(category, 10),
          currentAudience,
          date: publishingDate,
          description,
          duration: Number(selectedDuration),
          /* TODO: Make this values optional */
          eventTime: selectedEventHour ? `${dayjs(String(selectedEventHour)).format('HH:mm')}:00` : undefined,
          eventTimeZone: selectedEventTimezone || undefined,
          iconReference: selectedIcon,
          id: announcementId,
          link: redirectionLink || undefined,
          name,
          status: PublishingStatus.Scheduled,
          time: `${dayjs(String(selectedPublishingHour)).format('HH:mm')}:00`,
          timezone: selectedPublishingTimezone,
        }),
      );
      isSnackSuccessful = res.meta.requestStatus === 'fulfilled';
      snackMsg = 'The announcement has been edited';
      if (!isSnackSuccessful) snackErrorMsg = (res.payload as ErrorPayload).response.data.data.error;
    }

    if (isSnackSuccessful) navigate(`/announcements${isAnnouncementByGroup ? '' : '#individuals'}`);
    setSnackPack((prev) => [
      ...prev,
      {
        customErrorMessage: snackErrorMsg,
        key: new Date().getTime(),
        message: snackMsg,
        successful: isSnackSuccessful,
      },
    ]);
  };
  // Handler for Saving as Draft services
  const handleSaveAsDraft = async () => {
    let isSnackSuccessful = false;
    let snackMsg = 'Successful operation';
    let snackErrorMsg = 'There has been an error';

    if (service === Services.Create) {
      const res = await dispatch(
        postNewAnnouncement({
          audience: isAnnouncementByGroup ? selectedAudienceIdsArr : selectedAudienceArr,
          /* TODO: Make this values optional */
          categoryId: parseInt(category, 10),
          date: publishingDate || undefined,
          description: description || undefined,
          duration: Number(selectedDuration) || undefined,
          eventTime: selectedEventHour ? `${dayjs(String(selectedEventHour)).format('HH:mm')}:00` : undefined,
          eventTimeZone: selectedEventTimezone || undefined,
          iconReference: selectedIcon || undefined,
          link: redirectionLink || undefined,
          name,
          status: PublishingStatus.Draft,
          time: selectedPublishingHour ? `${dayjs(String(selectedPublishingHour)).format('HH:mm')}:00` : undefined,
          timezone: selectedPublishingTimezone || undefined,
        }),
      );
      isSnackSuccessful = res.meta.requestStatus === 'fulfilled';
      snackMsg = 'The announcement has been created';
      if (!isSnackSuccessful) snackErrorMsg = (res.payload as ErrorPayload).response.data.data.error;
    } else {
      const res = await dispatch(
        updateAnnouncement({
          audience: isAnnouncementByGroup ? selectedAudienceIdsArr : selectedAudienceArr,
          currentAudience,
          /* TODO: Make this values optional */
          categoryId: parseInt(category, 10),
          date: publishingDate || undefined,
          description: description || undefined,
          duration: Number(selectedDuration) || undefined,
          eventTime: selectedEventHour ? `${dayjs(String(selectedEventHour)).format('HH:mm')}:00` : undefined,
          eventTimeZone: selectedEventTimezone || undefined,
          iconReference: selectedIcon || undefined,
          id: announcementId,
          link: redirectionLink || undefined,
          name,
          status: PublishingStatus.Draft,
          time: selectedPublishingHour ? `${dayjs(String(selectedPublishingHour)).format('HH:mm')}:00` : undefined,
          timezone: selectedPublishingTimezone || undefined,
        }),
      );
      isSnackSuccessful = res.meta.requestStatus === 'fulfilled';
      snackMsg = 'The announcement has been edited';
      if (!isSnackSuccessful) snackErrorMsg = (res.payload as ErrorPayload).response.data.data.error;
    }

    if (isSnackSuccessful) navigate(`/announcements${isAnnouncementByGroup ? '' : '#individuals'}`);
    setSnackPack((prev) => [
      ...prev,
      {
        customErrorMessage: snackErrorMsg,
        key: new Date().getTime(),
        message: snackMsg,
        successful: isSnackSuccessful,
      },
    ]);
  };
  // Save Changes services handler
  const handleSaveChanges = async () => {
    let isSnackSuccessful = false;
    const snackMsg = 'The announcement has been edited';
    let snackErrorMsg = 'There has been an error';

    if (announcementSelected?.status === PublishingStatus.Published) {
      const res = await dispatch(
        updateAnnouncement({
          audience: isAnnouncementByGroup ? selectedAudienceIdsArr : selectedAudienceArr,
          currentAudience,
          /* TODO: Make this values optional */
          categoryId: parseInt(category, 10),
          description: description || undefined,
          duration: Number(selectedDuration) || undefined,
          eventTime: selectedEventHour ? `${dayjs(String(selectedEventHour)).format('HH:mm')}:00` : undefined,
          eventTimeZone: selectedEventTimezone || undefined,
          iconReference: selectedIcon || undefined,
          id: announcementId,
          link: redirectionLink || undefined,
          name,
        }),
      );
      isSnackSuccessful = res.meta.requestStatus === 'fulfilled';
      if (!isSnackSuccessful) snackErrorMsg = (res.payload as ErrorPayload).response.data.data.error;
    } else {
      const res = await dispatch(
        updateAnnouncement({
          audience: isAnnouncementByGroup ? selectedAudienceIdsArr : selectedAudienceArr,
          currentAudience,
          /* TODO: Make this values optional */
          categoryId: parseInt(category, 10),
          date: publishingDate || undefined,
          description: description || undefined,
          duration: Number(selectedDuration) || undefined,
          eventTime: selectedEventHour ? `${dayjs(String(selectedEventHour)).format('HH:mm')}:00` : undefined,
          eventTimeZone: selectedEventTimezone || undefined,
          iconReference: selectedIcon || undefined,
          id: announcementId,
          link: redirectionLink || undefined,
          name,
          time: selectedPublishingHour ? `${dayjs(String(selectedPublishingHour)).format('HH:mm')}:00` : undefined,
          timezone: selectedPublishingTimezone || undefined,
        }),
      );
      isSnackSuccessful = res.meta.requestStatus === 'fulfilled';
      if (!isSnackSuccessful) snackErrorMsg = (res.payload as ErrorPayload).response.data.data.error;
    }

    if (isSnackSuccessful) navigate(`/announcements${isAnnouncementByGroup ? '' : '#individuals'}`);
    setSnackPack((prev) => [
      ...prev,
      {
        customErrorMessage: snackErrorMsg,
        key: new Date().getTime(),
        message: snackMsg,
        successful: isSnackSuccessful,
      },
    ]);
  };

  // State handlers
  const handleIconChange = (event: SelectChangeEvent<string | string[]>) => {
    setSelectedIcon(event.target.value as string);
    setIsSaveChangesDisabled(false);
  };

  const handleCategoryChange = (event: SelectChangeEvent<string | string[]>) => {
    setCategory(event.target.value as string);
    setIsSaveChangesDisabled(false);
  };

  const handleNameChange = (event: SyntheticEvent) => {
    const { value } = event.target as HTMLTextAreaElement;
    setName(value);
    setIsNameInvalid(value.length > MAX_NAME_LENGTH);
    setIsSaveChangesDisabled(false);
  };

  const handleDescriptionChange = (event: SyntheticEvent) => {
    const { value } = event.target as HTMLTextAreaElement;
    setDescription(value);
    setIsSaveChangesDisabled(false);
  };

  const handleEventHourChange = (newHour: Dayjs | null) => {
    if (newHour) {
      // Calculus due to the data format
      const newHourFormatted = new Date(String(newHour)).toLocaleTimeString();
      setIsEventTimeInvalid(newHourFormatted === 'Invalid Date');
      setSelectedEventHour(newHour);
    }
    setIsSaveChangesDisabled(false);
  };

  const handleEventTimezoneChange = (event: SelectChangeEvent<string | string[]>) => {
    const { value } = event.target as HTMLSelectElement;
    setSelectedEventTimezone(value);
    setIsSaveChangesDisabled(false);
  };

  const handleAudienceChange = (event: SelectChangeEvent<string | string[]>) => {
    const {
      target: { value },
    } = event;
    setSelectedAudienceArr(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(', ') : value,
    );
    setIsSaveChangesDisabled(false);
  };

  const handleRedirLinkChange = (event: SyntheticEvent) => {
    const { value } = event.target as HTMLTextAreaElement;
    setIsRedirectionLinkValid(value === '' || isValidHttpUrl(value));
    setRedirectionLink(value);
    setIsSaveChangesDisabled(false);
  };

  const handleDurationChange = (event: SelectChangeEvent<string | string[]>) => {
    const { value } = event.target as HTMLSelectElement;
    if (isAnnouncementPublished && announcementSelected?.duration) {
      setIsEditDurationValid(Number(value) >= announcementSelected?.duration);
    }
    setSelectedDuration(value);
    setIsSaveChangesDisabled(false);
  };

  const handleDateChange = (newDate: Dayjs | null) => {
    if (newDate) {
      setIsDateInvalid(!newDate?.isValid() || newDate < today.subtract(1, 'day'));
      setPublishingDate(newDate);
    }
    setIsSaveChangesDisabled(false);
  };

  const handlePublishingHourChange = (newHour: Dayjs | null) => {
    if (newHour) {
      // Calculus due to the data format
      const newHourFormatted = new Date(String(newHour)).toLocaleTimeString();
      setIsPublishingTimeInvalid(newHourFormatted === 'Invalid Date');
      setSelectedPublishingHour(newHour);
    }
    setIsSaveChangesDisabled(false);
  };

  const handleTimezoneChange = (event: SelectChangeEvent<string | string[]>) => {
    const { value } = event.target as HTMLSelectElement;
    setSelectedPublishingTimezone(value);
    setIsSaveChangesDisabled(false);
  };

  // Handlers for opening and closing the Confirm Navigation Modal
  const handleOpenConfirmNavigationModal = () => setIsConfirmNavigationModalOpen(true);
  const handleCloseConfirmNavigationModal = () => setIsConfirmNavigationModalOpen(false);

  const setDisabledValidatorForConfirmNavigationModal = () => {
    if (service === Services.Create) {
      return isSaveDraftDisabled;
    }
    if (announcementSelected?.status === PublishingStatus.Draft) {
      return isSaveChangesDisabled || isSaveDraftDisabled;
    }
    return isSaveChangesDisabled || isPublishDisabled;
  };

  // Set the correct version of the edit form buttons
  const editButtons = {
    draft: {
      primary: {
        disabled: isPublishDisabled,
        label: 'Publish',
        onClick: handlePublish,
      },
      secondary: {
        disabled: isSaveChangesDisabled || isSaveDraftDisabled,
        label: 'Save Changes',
        onClick: handleSaveChanges,
      },
    },
    scheduled: {
      primary: {
        disabled: isSaveChangesDisabled || isPublishDisabled,
        label: 'Save Changes',
        onClick: handlePublish,
      },
      secondary: {
        disabled: isSaveDraftDisabled,
        label: 'Save as Draft',
        onClick: handleSaveAsDraft,
      },
    },
    published: {
      primary: {
        disabled: isSaveChangesDisabled || isPublishDisabled,
        label: 'Save Changes',
        onClick: handleSaveChanges,
      },
      secondary: {
        disabled: false,
        label: 'Cancel',
        onClick: handleOpenConfirmNavigationModal,
      },
    },
  };

  /* ========================================= USE EFFECTS ========================================= */
  // Fetch the correct type of audience
  useEffect(() => {
    if (isAnnouncementByGroup) {
      dispatch(readAllGroups());
    } else dispatch(readAllUsers());
  }, [dispatch, isAnnouncementByGroup]);

  // When editing, fetch the current info of the selected Announcement
  useEffect(() => {
    if (service === Services.Edit) {
      dispatch(readAnnouncementById(announcementId));
    }
  }, [announcementId, dispatch, service]);

  // When editing, set the current info in the inputs as the current values
  useEffect(() => {
    if (service === Services.Edit && announcementSelected) {
      if (announcementSelected.icon_reference) setSelectedIcon(`${announcementSelected.icon_reference}`);
      setCategory(getCategoryLabel(announcementSelected.categoryId, true));
      setName(announcementSelected.name);
      setDescription(announcementSelected.description || '');
      setSelectedEventHour(
        announcementSelected.event_time
          ? dayjs(`${announcementSelected.date} ${announcementSelected.event_time}`)
          : null,
      );
      setSelectedEventTimezone(announcementSelected.event_time_zone || '');
      const annAudience: string[] = [];
      announcementSelected.AudienceAnnouncement.forEach(({ fullName, group }) => {
        if (group) {
          if (!annAudience.includes(group.name)) {
            annAudience.push(group.name);
          }
        } else if (!annAudience.includes(fullName)) {
          annAudience.push(fullName);
        }
      });
      setSelectedAudienceArr(annAudience);
      setRedirectionLink(announcementSelected.link || '');
      if (announcementSelected.duration) setSelectedDuration(`${announcementSelected.duration}`);
      setPublishingDate(dayjs(announcementSelected.date));
      setSelectedPublishingHour(dayjs(`${announcementSelected.date} ${announcementSelected.time}`));
      setSelectedPublishingTimezone(announcementSelected.time_zone || '');
    }
  }, [announcementSelected, audienceType, service]);

  // Updates the selectedAudienceIdsArr values used for services when selectedAudienceArr changes
  useEffect(() => {
    if (isAnnouncementByGroup) {
      const audienceIds: number[] = [];
      selectedAudienceArr.forEach((selectedAudienceName) => {
        (availableAudience as Group[]).forEach(({ id, name: audienceName }) => {
          if (selectedAudienceName === audienceName) audienceIds.push(id);
        });
      });
      setSelectedAudienceIdsArr(audienceIds);
    }
  }, [availableAudience, isAnnouncementByGroup, selectedAudienceArr]);

  // Validates when the publish or save changes buttons are disabled
  useEffect(() => {
    setIsSaveDraftDisabled(isSaveDraftDisabledValidation);
    setIsPublishDisabled(isPublishDisabledValidation);
  }, [isPublishDisabledValidation, isSaveDraftDisabledValidation]);

  // TODO: Create a grid component to place every element in a view
  return (
    <Fragment>
      <Box className={styles.editContainer}>
        <aside>
          <ThemeProvider theme={azulDesign}>
            <Button variant='azulBackLink' onClick={handleOpenConfirmNavigationModal}>
              <BackIcon fill={primaryBlue} /> <span>&emsp;</span> Back
            </Button>
          </ThemeProvider>
        </aside>
        <main className={styles.mainContainer}>
          <header>
            <h2 className={styles.viewTitle}>
              {service === Services.Create ? 'Create New' : 'Edit'} {audienceType} Announcement
            </h2>
            <p className={styles.viewDescription}>
              {service === Services.Create
                ? 'Please fill all the inputs below to create a New Group Announcement.'
                : 'Please edit the fields you desire, and save changes to proceed.'}
            </p>
          </header>

          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '0 0 1rem 0' }}>
            {/* ============================== ICON SELECT ============================== */}
            <AzulSelect
              id='icon-select'
              label='Select Icon'
              labelId='icon-select-label'
              options={iconOptions}
              resetSearchOnClose={false}
              searchBar
              useMuiIcons
              value={selectedIcon}
              width='33%'
              onChange={handleIconChange}
            />

            {/* ============================== CATEGORY SELECT ============================== */}
            <AzulSelect
              id='category-select'
              label='Select Category'
              labelId='category-select-label'
              options={CATEGORIES}
              value={category}
              width='63%'
              onChange={handleCategoryChange}
            />
          </Box>

          {/* ============================== NAME INPUT ============================== */}
          <Box sx={{ margin: '0 0 2rem 0' }}>
            <AzulInput
              error={isNameInvalid}
              helperText={`${name.length}/${MAX_NAME_LENGTH}`}
              helperTextAlign='end'
              id='announcement-name'
              label='Announcement Name'
              value={name}
              width='100%'
              onChange={handleNameChange}
            />
          </Box>

          {/* ============================== DESCRIPTION INPUT ============================== */}
          <Box sx={{ margin: '0 0 6.8rem 0' }}>
            <AzulInput
              error={description.length > MAX_DESCRIPTION_LENGTH}
              helperText={`${description.length}/${MAX_DESCRIPTION_LENGTH}`}
              helperTextAlign='end'
              id='announcement-description'
              label='Announcement Description'
              multiline
              rows={3}
              value={description}
              width='100%'
              onChange={handleDescriptionChange}
            />
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            {/* ============================== EVENT TIME PICKER ============================== */}
            <AzulTimePicker
              format='hh:mm a'
              helperText={isEventTimeInvalid && selectedEventHour !== undefined ? 'Please choose a valid time.' : ''}
              label='Event Hour (optional)'
              value={selectedEventHour}
              width='33%'
              onChange={handleEventHourChange}
            />

            {/* ============================== EVENT TIMEZONE PICKER ============================== */}
            <AzulSelect
              disabled={!selectedEventHour || isEventTimeInvalid}
              id='event-timezone-select'
              label='Select Event Timezone'
              labelId='event-timezone-select-label'
              options={TIMEZONES}
              value={selectedEventTimezone}
              width='63%'
              onChange={handleEventTimezoneChange}
            />
          </Box>

          {/* =============================== SUBTITLE =============================== */}
          <Typography
            sx={{
              color: paragraphBaseline,
              fontWeight: 500,
              fontSize: '14px',
              letterSpacing: '0.01em',
              marginY: '2rem',
              textTransform: 'uppercase',
            }}
          >
            Establish the values for publishing the announcement
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '0 0 2rem 0' }}>
            {/* ============================== DURATION SELECT ============================== */}
            <AzulSelect
              error={!isEditDurationValid}
              helperText={!isEditDurationValid ? 'Currently only to extend the duration.' : ''}
              id='duration-select'
              label='Set Duration'
              labelId='duration-select-label'
              options={DURATIONS}
              value={selectedDuration}
              width='33%'
              onChange={handleDurationChange}
            />

            {/* ============================== AUDIENCE SELECT ============================== */}
            <AzulSelect
              id='audience-select'
              label='Select Audience'
              labelId='audience-select-label'
              multiple
              options={audienceOptions}
              searchBar
              value={selectedAudienceArr}
              width='63%'
              onChange={handleAudienceChange}
            />
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '0 0 3.25rem 0' }}>
            {/* ============================== DATE PICKER ============================== */}
            <AzulDatePicker
              disablePast
              disabled={isAnnouncementPublished}
              helperText={isDateInvalid ? 'Please choose a valid date.' : 'MM/DD/YYYY'}
              label='Pick Date'
              value={publishingDate}
              width='33%'
              onChange={handleDateChange}
            />

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '63%',
              }}
            >
              {/* ============================== TIME PICKER ============================== */}
              <AzulTimePicker
                disabled={isAnnouncementPublished}
                format='hh:mm a'
                helperText={isPublishingTimeInvalid && selectedPublishingHour ? 'Please choose a valid time.' : ''}
                label='Set Hour'
                value={selectedPublishingHour}
                width='47.5%'
                onChange={handlePublishingHourChange}
              />

              {/* ============================== TIMEZONE PICKER ============================== */}
              <AzulSelect
                disabled={isAnnouncementPublished}
                id='publishing-timezone-select'
                label='Select Timezone'
                labelId='publishing-timezone-select-label'
                options={TIMEZONES}
                value={selectedPublishingTimezone}
                width='47.5%'
                onChange={handleTimezoneChange}
              />
            </Box>
          </Box>

          <Box sx={{ margin: '0 0 1rem 0' }}>
            {/* ============================== REDIRECTION LINK INPUT ============================== */}
            <AzulInput
              error={!isRedirectionLinkValid}
              helperText={!isRedirectionLinkValid ? 'Please enter a valid url.' : ''}
              id='redirection-link'
              label='Redirection Link (Optional)'
              margin='0 0 2rem 0'
              placeholder='Insert Redirection Link'
              value={redirectionLink}
              width='100%'
              onChange={handleRedirLinkChange}
            />
          </Box>

          {/* ========================== PUBLISHING DATE & TIME PREVIEW ========================== */}
          <Box className={styles.info}>
            <ExclamationMark title='Publication information' /> &nbsp;&nbsp;&nbsp;
            {publishingDateTimePreview}
          </Box>

          {/* ================================ FORM ACTIONS ================================ */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              margin: '1.5rem 0 0 0',
            }}
          >
            <ThemeProvider theme={azulDesign}>
              {service === Services.Create && (
                <Fragment>
                  <Button disabled={isSaveDraftDisabled} variant='azulSecondary' onClick={handleSaveAsDraft}>
                    Save as Draft
                  </Button>

                  <Button disabled={isPublishDisabled} variant='azulPrimary' onClick={handlePublish}>
                    Publish
                  </Button>
                </Fragment>
              )}
              {service === Services.Edit &&
                announcementSelected &&
                announcementSelected.status !== PublishingStatus.Finished && (
                  <Fragment>
                    <Button
                      disabled={editButtons[announcementSelected.status].secondary.disabled}
                      variant='azulSecondary'
                      onClick={editButtons[announcementSelected.status].secondary.onClick}
                    >
                      {editButtons[announcementSelected.status].secondary.label}
                    </Button>

                    <Button
                      disabled={editButtons[announcementSelected.status].primary.disabled}
                      variant='azulPrimary'
                      onClick={editButtons[announcementSelected.status].primary.onClick}
                    >
                      {editButtons[announcementSelected.status].primary.label}
                    </Button>
                  </Fragment>
                )}
            </ThemeProvider>
          </Box>
        </main>
      </Box>

      <AzulConfirmNavigationModal
        navigateTo={`/announcements${isAnnouncementByGroup ? '' : '#individuals'}`}
        open={isConfirmNavigationModalOpen}
        saveChanges={service === Services.Create ? handleSaveAsDraft : handleSaveChanges}
        saveChangesDisabled={setDisabledValidatorForConfirmNavigationModal()}
        onClose={handleCloseConfirmNavigationModal}
      />
      <SimpleSnackbar />
    </Fragment>
  );
};

export default AnnouncementForm;
