import { FC, useContext, useState, useEffect, Fragment, ChangeEvent } from 'react';
import { AudienceType } from 'utils/constants/audienceType';
import { ReactComponent as EditIcon } from '../../assets/Edit.svg';
import { removeAnnouncementById } from '../../store/features/announcementSlice';
import snackContext from '../../store/features/contextSnackbar';
import { updateGroupNameById } from '../../store/features/groupSlice';
import { useAppDispatch } from '../../utils/hooks/storeHooks';
import buttonStyles from '../blueAddBtn/BlueAddBtn.module.scss';
import { BlueAddButton } from '../blueAddBtn/StyledBtn';
import AzulInput from '../simpleComponents/AzulInput';
import AzulModal from '../simpleComponents/AzulModal';

type EditModalProps = {
  actionLabel: string; // The label for the confirm edit button
  cancelActionLabel?: string; // The label for canceling the edit button
  crudId?: number; // The ID that will be used in the edit service
  label: string; // The general label for the modal, so you know what are you editing
};

const EditModal: FC<EditModalProps> = ({
  actionLabel,
  cancelActionLabel = 'Cancel',
  crudId = 0,
  label,
}: EditModalProps) => {
  const dispatch = useAppDispatch();
  const { setSnackPack } = useContext(snackContext);
  /* States */
  const [open, setOpen] = useState<boolean>(false);
  const [newGroupName, setNewGroupName] = useState<string>('');
  const [isConfirmBtnDisabled, setIsConfirmBtnDisabled] = useState<boolean>(true);

  // Setting the message sent to the snackbar if an edit happens
  const snackMsg: string =
    label.toLowerCase() === AudienceType.group
      ? `${newGroupName} has been changed successfully`
      : `The member has been changed succesfully`;

  // Handler to enable or disable the confirm creation button
  useEffect(() => {
    setIsConfirmBtnDisabled(!newGroupName);
  }, [newGroupName]);

  // Handler for updating the name
  const handleNameChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setNewGroupName(value);
  };
  // Handler for closing the modal and emptying the group name input value inside the modal
  const handleClose = () => {
    setNewGroupName('');
    setOpen(false);
  };
  // Handler for opening the modal
  const handleOpen = () => {
    setOpen(true);
  };
  // Handler for editing
  const handleEdit = async (groupId: number, groupName: string) => {
    let isSnackSuccessful: boolean;
    switch (label.toLowerCase()) {
      case 'group':
        isSnackSuccessful =
          (await dispatch(updateGroupNameById({ id: groupId, newName: groupName }))).meta.requestStatus === 'fulfilled';
        break;

      case 'announcement':
        // Will change the service eventually to the update announcement service
        isSnackSuccessful = (await dispatch(removeAnnouncementById(groupId))).meta.requestStatus === 'fulfilled';
        break;

      default:
        break;
    }
    handleClose();
    setSnackPack((prev) => [...prev, { message: snackMsg, successful: isSnackSuccessful, key: new Date().getTime() }]);
  };

  return (
    <AzulModal
      modalActions={
        <Fragment>
          {cancelActionLabel && (
            <button
              className={`${buttonStyles.actionBtn} ${buttonStyles.cancelAction}`}
              style={{ marginRight: '.5rem' }}
              type='button'
              onClick={handleClose}
            >
              {cancelActionLabel}
            </button>
          )}

          {/* Has logic for checking if a new name was entered 
              Input has values -> enable button and close with setOpen(false) 
              Input is empty -> disable button and stay open */}
          <BlueAddButton
            className={`${buttonStyles.actionBtn} ${buttonStyles.doAction}`}
            disableElevation
            disabled={isConfirmBtnDisabled}
            variant='contained'
            onClick={() => {
              handleEdit(crudId, newGroupName);
            }}
          >
            {actionLabel}
          </BlueAddButton>
        </Fragment>
      }
      modalContent={
        <AzulInput
          label='Name of the group'
          placeholder='Type a new Name'
          value={newGroupName}
          width='100%'
          onChange={handleNameChange}
        />
      }
      open={open}
      title={`Edit ${label} Name`}
      onClose={handleClose}
    >
      <button className={buttonStyles.deleteMemberBtn} onClick={handleOpen}>
        <EditIcon style={{ alignSelf: 'center' }} />
      </button>
    </AzulModal>
  );
};

export default EditModal;
