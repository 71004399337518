import { azulDesign } from 'components/simpleComponents/AzulDesignTheme';
import { useEffect, useState, FC } from 'react';
import { readAllUsers, readAvailableUsersToAdd, readRegularUsers } from 'store/features/userSlice';
import { useAppDispatch, useAppSelector } from 'utils/hooks/storeHooks';
import { generalUser } from 'utils/parsers/generalUser';
import Add from '@mui/icons-material/Add';
import {
  Box,
  ThemeProvider,
  Typography,
  chipClasses,
  formHelperTextClasses,
  inputLabelClasses,
  outlinedInputClasses,
  paperClasses,
} from '@mui/material';
import Autocomplete, { autocompleteClasses, createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ReactComponent as CloseSVG } from '../../assets/Close.svg';
import { paragraphBaseline, primaryBlue, neutralGray, accentBlue } from '../../styles/partials/variables';
import { SynkronUser, User, UsersIdArray } from '../../type/user';
import LoadingSkeleton from './LoadingSkeleton';

type SearchAutocompleteProps = {
  groupId?: number;
  helperText?: string;
  helperTextAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
  label?: string;
  placeholder?: string;
  setUsersIdArr: ([{ memberEmail, memberName, memberUid }]: UsersIdArray[]) => void;
};

export const SearchAutocomplete: FC<SearchAutocompleteProps> = ({
  groupId,
  helperText,
  helperTextAlign = 'start',
  label = 'Members',
  placeholder = 'Type a name or email',
  setUsersIdArr,
}: SearchAutocompleteProps) => {
  const dispatch = useAppDispatch();
  const synkronUsers: SynkronUser[] | User[] = useAppSelector((state) => state.user.users);
  const loadingSynkronUsers = useAppSelector((state) => state.user.loading);
  const [loading, setLoading] = useState(true); // for showing the loading skeleton while data is being fetched
  const [listUsers, setListUsers] = useState<SynkronUser[]>([]); // TO DO: Verify why is this value emptying sometimes, renders as the options list
  const filterOptions = createFilterOptions({
    // filters the rendered options displayed on the list
    matchFrom: 'any',
    stringify: (option: SynkronUser) => option.fullName,
    limit: 10,
  });

  useEffect(() => {
    if (label === 'New Admins') {
      setLoading(loadingSynkronUsers);
    } else if (!groupId) {
      setLoading(loadingSynkronUsers);
    } else {
      setLoading(loadingSynkronUsers);
    }
  }, [groupId, label, loadingSynkronUsers]);

  // Updates the users displayed in the list every time the input's search changes
  useEffect(() => {
    if (label === 'New Admins') {
      dispatch(readRegularUsers());
    } else if (!groupId) {
      // TODO: don't fetch all users, but only those related to what the user is typing
      dispatch(readAllUsers());
    } else {
      dispatch(readAvailableUsersToAdd(groupId));
    }
  }, [groupId, label, dispatch]);

  useEffect(() => {
    if (label === 'New Admins') {
      setListUsers(generalUser(synkronUsers));
    } else if (!groupId) {
      setListUsers(generalUser(synkronUsers));
    } else {
      setListUsers(generalUser(synkronUsers));
    }
  }, [groupId, label, synkronUsers]);

  return (
    <ThemeProvider theme={azulDesign}>
      <Autocomplete
        ChipProps={{
          deleteIcon: <CloseSVG />,
          sx: {
            backgroundColor: accentBlue,
            color: primaryBlue,
            height: '1.5rem',
            paddingRight: '.25rem',
            [`&.${autocompleteClasses.tag}`]: {
              borderRadius: '8px',
              margin: '0 .5rem 0 0',
            },
            [`& .${chipClasses.label}`]: {
              paddingLeft: '.5rem',
            },
          },
        }}
        autoComplete
        componentsProps={{
          paper: {
            elevation: 3,
            sx: {
              [`&.${paperClasses.root}`]: { padding: 0, minWidth: 0 },
            },
          },
        }} // shadow effect on the list
        disableClearable
        disablePortal
        filterOptions={filterOptions}
        filterSelectedOptions
        forcePopupIcon
        getOptionLabel={(option) => option.fullName ?? option.email} // The Chip's label when selecting a user from the list
        id='search-members-list'
        limitTags={2} // A 2+ will be shown when selecting > 2 users
        loading={loading}
        loadingText={<LoadingSkeleton />}
        multiple
        noOptionsText='No results found.'
        options={listUsers} // must be an array
        renderInput={(params) => (
          // Allows to personalize the input's rendering format
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              type: 'search',
              sx: {
                fontFamily: 'Rubik',
                [`&.${outlinedInputClasses.root}`]: {
                  padding: '.75rem',
                  [`& .${autocompleteClasses.input}`]: {
                    height: '1.5rem',
                    padding: '0 4px 0 6px',
                  },
                  '& fieldset': {
                    borderColor: paragraphBaseline,
                    borderRadius: '8px',
                  },
                },
                [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                  borderColor: `${primaryBlue}!important`,
                  borderWidth: '2px',
                },
                [`.${formHelperTextClasses.root}`]: {
                  margin: '0.25rem 1rem 0 1rem',
                  fontWeight: 400,
                  fontSize: '.75rem',
                  lineHeight: '1rem',
                  letterSpacing: '0.008em',
                  color: paragraphBaseline,
                  textAlign: helperTextAlign,
                },
              },
            }}
            helperText={helperText}
            label={label}
            placeholder={placeholder}
            value={listUsers}
          />
        )}
        renderOption={(props, option) => (
          // TO DO: check how to modify the key used in the ul element -> set to uid azure
          // Allows to edit the list's individual options format rendering
          <li {...props}>
            <Typography variant='avatarInitial'>{option.fullName[0]}</Typography>
            <Box
              sx={{
                color: paragraphBaseline,
                display: 'flex',
                alignItems: 'center',
                width: '90%',
                height: '18px', // set to 16px if overflow is ommited
                marginLeft: '0.8rem',
                marginRight: '0.3rem',
                wordBreak: 'break-all',
                // TODO: Verify scroll bar
                overflow: 'hidden',
                // TO DO: customize scroll bar width
              }}
            >
              <Typography
                sx={{
                  color: { paragraphBaseline },
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '24px',
                  minWidth: 'max-content',
                }}
              >
                {' '}
                {option.fullName} &nbsp; &nbsp;{' '}
              </Typography>
              <Typography color={neutralGray}>|</Typography>
              <Typography
                sx={{
                  color: { paragraphBaseline },
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '24px',
                  minWidth: 'max-content',
                }}
              >
                {' '}
                &nbsp; &nbsp; {option.email}{' '}
              </Typography>
            </Box>
            <Add sx={{ color: primaryBlue }} />
          </li>
        )}
        sx={{
          [`& .${inputLabelClasses.root}`]: {
            fontFamily: 'Rubik',
            top: '-0.225rem',
            '&.Mui-focused': {
              color: primaryBlue,
            },
          },
        }}
        onChange={(ev, value) => {
          setUsersIdArr(
            value.map(({ email, fullName, uidAzure }) => ({
              memberEmail: email,
              memberName: fullName,
              memberUid: uidAzure,
            })),
          );
        }}
      />
    </ThemeProvider>
  );
};
