import { Credentials } from 'type/credentials';
import { EmailAuth } from 'type/emailAuth';
import { User } from 'type/user';
import { createSlice } from '@reduxjs/toolkit';
import { SessionStatus } from '../../utils/constants/sessionStatus';
import reducers from './reducer';

export const TOKEN_REFRESH_CHECK_INTERVAL = 60000;
export const TOKEN_REFRESH_THRESHOLD = 3600000; // 1 hour
export const LOCAL_STORAGE_CREDENTIALS_KEY = 'credentials';
export const LOCAL_STORAGE_USER_EMAIL = 'username';

/*
  Credentials logic:
    - On login, the front puts credentials on a cookie (jwt)
    - The app gets those credentials and saves them to localStorage
    - On consecutive app loads, the source of truth is localStorage
    - When a token gets refreshed, the credentials are updated on localStorage
    - On log out, both localStorage and cookies should be cleared
    - On production, we cannot clear cookies using JS, we need to redirect to an
      api endpoint so the back instructs the browser to clear it with a header in the response
*/
export const getSavedCredentials = (): Credentials | undefined => {
  let credentials: Credentials | undefined;
  try {
    // Try to get from localstorage
    const localCredentials = localStorage.getItem(LOCAL_STORAGE_CREDENTIALS_KEY);
    if (localCredentials) {
      credentials = JSON.parse(localCredentials);
    }
  } catch (err) {
    console.error('Error getting credentials:', err);
  }

  return credentials;
};

export const setSavedCredentials = (credentials: Credentials): void => {
  localStorage.setItem(LOCAL_STORAGE_CREDENTIALS_KEY, JSON.stringify(credentials));
};

export const clearSavedCredentials = (): void => {
  localStorage.removeItem(LOCAL_STORAGE_CREDENTIALS_KEY);
};

export const getUserEmail = (): Partial<EmailAuth> | undefined => {
  let email: Partial<EmailAuth> | undefined;
  try {
    // Try to get from localstorage
    const localUsername = localStorage.getItem(LOCAL_STORAGE_USER_EMAIL);
    if (localUsername) {
      email = JSON.parse(localUsername);
    }
  } catch (err) {
    console.error('Error getting email:', err);
  }

  return email;
};

// Runs when the "remember me" box is checked
export const setSavedUsername = (email: string): void => {
  const Email = { email };
  localStorage.setItem(LOCAL_STORAGE_USER_EMAIL, JSON.stringify(Email));
};

export const clearSavedUsername = (): void => {
  localStorage.removeItem(LOCAL_STORAGE_USER_EMAIL);
};

export interface SessionState {
  credentials?: Credentials;
  currentUser?: User;
  isSessionConfirmed: boolean;
  isAuthenticated: boolean;
  status: SessionStatus;
  hasError: boolean;
  errorMessage: string;
  isLoading: boolean;
  isForgotPasswordSuccessful: boolean;
  isRegisterSuccessful: boolean;
  isRefreshing: boolean;
}

const initialState: SessionState = {
  isSessionConfirmed: false,
  isAuthenticated: false,
  status: SessionStatus.initial,
  hasError: false,
  errorMessage: '',
  isLoading: false,
  isForgotPasswordSuccessful: false,
  isRegisterSuccessful: false,
  isRefreshing: false,
};

initialState.credentials = undefined;

export const getInfoByIdMode = process.env.REACT_APP_DEVELOPMENT_MODE === 'true';

initialState.currentUser =
  process.env.REACT_APP_DEVELOPMENT_MODE === 'true'
    ? {
        authType: 'microsoft',
        department: 'ui/js',
        email: 'dannae.diaz@theksquaregroup.com',
        firstName: 'Dannae',
        isActive: true,
        id: 671,
        isEmailConfirmed: true,
        lastName: 'Diaz',
        name: 'Dannae Diaz',
        uid_azure: 'b70513a8-b469-49c9-a498-7ee90cf6d120',
      }
    : undefined;

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers,
});

export const {
  logOut,
  confirmSession,
  setCredentials,
  updateCurrentUser,
  updateIsAuthenticated,
  updateSessionStatus,
  setHasError,
  setErrorMessage,
  setIsForgotPasswordSuccessful,
  setIsLoading,
  setIsRegisterSuccessful,
  setIsRefreshing,
} = sessionSlice.actions;

export default sessionSlice.reducer;
