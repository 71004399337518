import AnnouncementFiltersModal from 'components/announcementFiltersModal/AnnouncementFilterModal';
import { azulDesign } from 'components/simpleComponents/AzulDesignTheme';
import { Fragment, SyntheticEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Button, Tab, ThemeProvider, Typography } from '@mui/material';
import announcementsEmptyState from '../../assets/emptyStates/announcements_emptyState.svg';
import { BlueAddButton } from '../../components/blueAddBtn/StyledBtn';
import { SearchBar } from '../../components/searchBar/SearchBar';
import SectionHeader from '../../components/sectionHeader/SectionHeader';
import AzulContainer from '../../components/simpleComponents/AzulContainer';
import AzulEmptyState from '../../components/simpleComponents/AzulEmptyState';
import AzulLoadingState from '../../components/simpleComponents/AzulLoadingState';
import SimpleSnackbar from '../../components/snackbar/Snackbar';
import { StyledAnnouncementTable } from '../../components/tableList/announcementsTable/StyledAnnouncementsTable';
import { readAllAnnouncements } from '../../store/features/announcementSlice';
import { Announcement } from '../../type/announcement';
import { AudienceType } from '../../utils/constants/audienceType';
import { useAppDispatch, useAppSelector } from '../../utils/hooks/storeHooks';
import styles from './Announcements.module.scss';

const Announcements = () => {
  // To navigate other parte of the app through the routing
  const navigate = useNavigate();
  const { hash: urlHash } = useLocation();
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState<boolean>(false);
  const [audienceFilterArr, setAudienceFilterArr] = useState<string[]>([]);
  const [dateRangeFilters, setDateRangeFilters] = useState<number[]>([]);
  const [statusFilters, setStatusFilters] = useState<{
    published: boolean;
    scheduled: boolean;
    draft: boolean;
  }>({
    published: false,
    scheduled: false,
    draft: false,
  });
  const [categoriesFilters, setCategoriesFilters] = useState<{
    'Area Info Related': boolean;
    Community: boolean;
    Training: boolean;
    Activities: boolean;
    Social: boolean;
    'For All': boolean;
  }>({
    'Area Info Related': false,
    Community: false,
    Training: false,
    Activities: false,
    Social: false,
    'For All': false,
  });
  const [searchValue, setSearchValue] = useState<string>('');
  const [tab, setTab] = useState<string>(urlHash.replace('#', '') || 'groups');
  const dispatch = useAppDispatch();
  const groupAnnouncements = useAppSelector((state) => state.announcement.groupAnnouncements);
  const individualAnnouncements = useAppSelector((state) => state.announcement.individualAnnouncements);
  const isAnnouncementListLoading = useAppSelector((state) => state.announcement.loading);
  const isAnnouncementListEmpty =
    (tab === 'individuals' && !individualAnnouncements.length) || (tab === 'groups' && !groupAnnouncements.length);

  // Filters the Users array inside the table, user by user. Returns the users that match the search value
  const tableSearch = (AnnouncementList: Array<Announcement>) =>
    AnnouncementList.filter((announcement: Announcement) => {
      let audienceMatch = false;
      announcement.AudienceAnnouncement.forEach(({ fullName, group }) => {
        if (group?.name.toLowerCase().includes(searchValue) || fullName.toLowerCase().includes(searchValue)) {
          audienceMatch = true;
        }
      });
      return announcement.name.toLowerCase().includes(searchValue) || audienceMatch;
    });
  const filteredIndividualAnnouncements = tableSearch(individualAnnouncements);
  const filteredGroupAnnouncements = tableSearch(groupAnnouncements);

  // Updates the Announcements array (to be passed into the table search) every time a new input value is entered into the search bar
  useEffect(() => {
    dispatch(readAllAnnouncements());
  }, [searchValue, dispatch]);

  // Handler for the Create New button
  const handleCreateNew = () => {
    navigate(`create/${tab}`);
  };
  // Handler to display the chosen tab
  const handleTabChange = (event: SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <Fragment>
      <Box>
        <AzulContainer>
          <SectionHeader name='Announcements' />

          <Box display='flex' justifyContent='space-between'>
            <Typography className={styles.subHeader}>
              Disseminate public announcements to all company personnel.
            </Typography>
            {!isAnnouncementListEmpty && (
              <BlueAddButton
                className={`${styles.actionBtn} ${styles.doAction}`}
                disableElevation
                variant='contained'
                onClick={handleCreateNew}
              >
                Create New
              </BlueAddButton>
            )}
          </Box>

          <Box sx={{ marginY: '3rem', width: '100%', typography: 'body1' }}>
            <TabContext value={tab}>
              <Box display='flex' justifyContent='space-between' marginBottom='2rem' width='max-width'>
                {/* The tabs to toggle between group and individual announcement lists */}
                <TabList
                  TabIndicatorProps={{ style: { backgroundColor: '#2A5BDF' } }}
                  aria-label='announcement tabs'
                  onChange={handleTabChange}
                >
                  {/* Will proably style them later in a separate file and import as StyledTab */}
                  <Tab
                    disableRipple
                    label={AudienceType.Group}
                    sx={{
                      '&.Mui-selected': {
                        color: '#2a5bdf',
                        fontWeight: 500,
                      },
                      fontWeight: 400,
                      fontSize: '1rem',
                      lineHeight: '1.5rem',
                      fontFamily: 'Rubik, sans serif',
                      textTransform: 'none',
                    }}
                    value='groups'
                  />
                  <Tab
                    disableRipple
                    label={AudienceType.Individual}
                    sx={{
                      '&.Mui-selected': {
                        color: '#2a5bdf',
                        fontWeight: 500,
                      },
                      fontWeight: 400,
                      fontSize: '1rem',
                      lineHeight: '1.5rem',
                      fontFamily: 'Rubik, sans serif',
                      textTransform: 'none',
                    }}
                    value='individuals'
                  />
                </TabList>
                <Box sx={{ alignItems: 'center', display: 'flex', width: '60%' }}>
                  {!isAnnouncementListEmpty && (
                    <Fragment>
                      {/* FILTERS BUTTON */}
                      <ThemeProvider theme={azulDesign}>
                        <Button
                          sx={{ margin: '0 1rem 0 0', width: '108px' }}
                          variant='azulSecondary'
                          onClick={() => setIsFiltersModalOpen(true)}
                        >
                          <FilterAltIcon fontSize='small' /> Filter
                        </Button>
                      </ThemeProvider>
                      {/* SEARCHBAR */}
                      <SearchBar placeholderTxt='Search by announcement' searchQuery={(val) => setSearchValue(val)} />
                    </Fragment>
                  )}
                </Box>
              </Box>
              <TabPanel sx={{ padding: '0' }} value='groups'>
                {/* The data prop searches the table with the query/value entered in the search bar component */}
                {isAnnouncementListLoading && (
                  <AzulLoadingState
                    height='50vh'
                    subtitle='We are loading the group announcements list, this may take a few seconds.'
                    title='Please wait. Loading the Announcement List'
                  />
                )}
                {!isAnnouncementListLoading &&
                  (groupAnnouncements.length ? (
                    <StyledAnnouncementTable
                      audienceFilters={audienceFilterArr}
                      audienceType='groups'
                      categoriesFilters={categoriesFilters}
                      count={tableSearch(filteredGroupAnnouncements).length}
                      data={tableSearch(filteredGroupAnnouncements)}
                      dateFilters={dateRangeFilters}
                      statusFilters={statusFilters}
                    />
                  ) : (
                    <AzulEmptyState
                      buttonAction={handleCreateNew}
                      buttonLabel='Create New Announcement'
                      imgAriaLabel='No results found.'
                      imgHeight='256px'
                      imgSrc={announcementsEmptyState}
                      imgWidth='256px'
                      subTitle="It's time to broadcast some updates to the entire team"
                      title='No announcements made yet.'
                    />
                  ))}
              </TabPanel>
              <TabPanel sx={{ padding: '0' }} value='individuals'>
                {/* The data prop searches the table with the query/value entered in the search bar component */}
                {isAnnouncementListLoading && (
                  <AzulLoadingState
                    height='50vh'
                    subtitle='We are loading the individual announcements list, this may take a few seconds.'
                    title='Please wait. Loading the Announcement List'
                  />
                )}
                {!isAnnouncementListLoading &&
                  (individualAnnouncements.length ? (
                    <StyledAnnouncementTable
                      audienceFilters={audienceFilterArr}
                      audienceType='individuals'
                      categoriesFilters={categoriesFilters}
                      count={tableSearch(filteredIndividualAnnouncements).length}
                      data={tableSearch(filteredIndividualAnnouncements)}
                      dateFilters={dateRangeFilters}
                      statusFilters={statusFilters}
                    />
                  ) : (
                    <AzulEmptyState
                      buttonAction={handleCreateNew}
                      buttonLabel='Create New Announcement'
                      imgAriaLabel='No results found.'
                      imgHeight='256px'
                      imgSrc={announcementsEmptyState}
                      imgWidth='256px'
                      subTitle="It's time to broadcast some updates to the entire team"
                      title='No announcements made yet.'
                    />
                  ))}
              </TabPanel>
            </TabContext>
          </Box>
        </AzulContainer>
      </Box>

      <AnnouncementFiltersModal
        audienceFilters={(audience) => setAudienceFilterArr(audience)}
        categoriesFilters={(categoriesObj) => setCategoriesFilters(categoriesObj)}
        dateFilters={(dateRange) => setDateRangeFilters(dateRange)}
        isAnnouncementByGroup={tab === 'groups'}
        open={isFiltersModalOpen}
        statusFilters={(statusObj) => setStatusFilters(statusObj)}
        onClose={() => setIsFiltersModalOpen(false)}
      />
      <SimpleSnackbar />
    </Fragment>
  );
};

export default Announcements;
