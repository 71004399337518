import dayjs from 'dayjs';
import { FC, Fragment, useContext, useState } from 'react';
import { Background } from 'type/background';
import { ReactComponent as DuplicateSVG } from '../../assets/Duplicate.svg';
import { postNewBackground, readAllBackgrounds, readBackgroundById } from '../../store/features/backgroundSlice';
import snackContext from '../../store/features/contextSnackbar';
import { useAppDispatch } from '../../utils/hooks/storeHooks';
import styles from '../../views/groups/Groups.module.scss';
import buttonStyles from '../blueAddBtn/BlueAddBtn.module.scss';
import { BlueAddButton } from '../blueAddBtn/StyledBtn';
import { StyledMenuItem } from '../moreSettingsBtn/StyledComponents';
import AzulModal from '../simpleComponents/AzulModal';

type DuplicateModalProps = {
  actionLabel?: string; // The label on the button to confirm the duplicate action
  audienceType: 'groups' | 'individuals'; // Used to validate the audience type for the bg services
  cancelActionLabel?: string; // The label on the button to cancel the action
  crudId?: number; // The ID that will be used in the duplicate service
  label: string; // The general label for the modal, so you know what are you duplicating
};

const DuplicateModal: FC<DuplicateModalProps> = ({
  actionLabel = 'Yes, Duplicate',
  audienceType,
  cancelActionLabel = 'Cancel',
  crudId = 0,
  label,
}: DuplicateModalProps) => {
  const dispatch = useAppDispatch();
  const { setSnackPack } = useContext(snackContext); // to trigger toast notification
  const [open, setOpen] = useState<boolean>(false);

  /* Handlers for opening and closing the DuplicateModal */
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  /* Handler for the duplicating operation */
  const handleDuplicate = async (bgId: number) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { payload }: any = await dispatch(readBackgroundById(bgId)); // get the bg and its data to duplicate, cant remove "any"
    const { AudienceBackground, checksum, date, draftData, duration, editableCanvas, name, status, image }: Background =
      payload;
    // The audience could either be of groups (array of group ids) or individual users (array of emails)
    const selectAudience = () => {
      if (audienceType === 'groups') {
        let audienceGroupIdArr = AudienceBackground.map(({ groupId }) => {
          if (groupId) return groupId;
          return 0;
        }).filter((val) => val !== 0);
        audienceGroupIdArr = Array.from(new Set(audienceGroupIdArr)); // returns an array with the unique (non repeated) values of the array
        return audienceGroupIdArr;
      } // otherwise if the audince type is individuals we get the emails
      const audienceStrArr = AudienceBackground.map(({ email }) => email);
      return audienceStrArr;
    };

    const audienceArr = selectAudience();
    const res = await dispatch(
      postNewBackground({
        name: `${name} copy`,
        image: image ?? undefined,
        status,
        audience: audienceArr,
        checksum,
        duration,
        date: dayjs(date),
        draftData,
        editableCanvas,
      }),
    );
    const isSnackSuccessful = res.meta.requestStatus === 'fulfilled';
    const snackMsg = `${label} "${name}" has been duplicated`;
    dispatch(readAllBackgrounds()); // updates the bgs table to show new bg after duplicating action
    handleClose();
    setSnackPack((prev) => [...prev, { message: snackMsg, successful: isSnackSuccessful, key: new Date().getTime() }]);
  };

  return (
    <AzulModal
      modalActions={
        <Fragment>
          {/* ONLY renders the twin cancel button if cancelActionLabel prop was provided */}
          {cancelActionLabel && (
            <button
              className={`${buttonStyles.actionBtn} ${buttonStyles.cancelAction}`}
              style={{ marginRight: '.5rem' }}
              type='button'
              onClick={handleClose}
            >
              {cancelActionLabel}
            </button>
          )}

          <BlueAddButton
            className={`${buttonStyles.actionBtn} ${buttonStyles.doAction}`}
            disableElevation
            variant='contained'
            onClick={() => {
              handleDuplicate(crudId);
            }}
          >
            {actionLabel}
          </BlueAddButton>
        </Fragment>
      }
      open={open}
      subtitle={`Are you sure you want to duplicate this ${label.toLowerCase()} ?`}
      title={`Duplicate ${label}`}
      onClose={() => {
        handleClose();
      }}
    >
      <StyledMenuItem
        TouchRippleProps={{ style: { color: '#C0D1FF' } }}
        onClick={() => {
          handleOpen();
        }}
      >
        <DuplicateSVG style={{ marginRight: '.75rem' }} />
        <p className={`${styles.P1} ${styles.pointer}`}>Duplicate {label}</p>
      </StyledMenuItem>
    </AzulModal>
  );
};

export default DuplicateModal;
