import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import snackContext from 'store/features/contextSnackbar';
import { setHasError, setIsLoading, getUserEmail } from 'store/session';
import { EmailAuth } from 'type/emailAuth';
import { SSOLoginStatus, SSOErrorMessage } from 'utils/constants/sso';
import { Box, Typography, Button, ThemeProvider, Checkbox, TextField } from '@mui/material';
import { ReactComponent as CheckboxIcon } from '../../assets/Checkbox.svg';
import { ReactComponent as CheckboxOutlineIcon } from '../../assets/CheckboxOutline.svg';
import TKGLogo from '../../assets/TKGLogo.png';
import loginImg from '../../assets/loginImg.png';
import { ReactComponent as MSLogo } from '../../assets/ms-symbol.svg';
import { azulDesign } from '../../components/simpleComponents/AzulDesignTheme';
import SimpleSnackbar from '../../components/snackbar/Snackbar';
import { selectHasError, selectIsAuth } from '../../store/session/selectors';
import { logInWithExchange, logInWithEmail } from '../../store/session/thunks';
import { primaryHoverBlue2, backgroundFocusBlue, backgroundWhite } from '../../styles/partials/variables';
import { useAppDispatch } from '../../utils/hooks/storeHooks';

const Login = () => {
  const { setSnackPack } = useContext(snackContext);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const location = useLocation();
  const isAuthenticated = useSelector(selectIsAuth);
  const authFailed = useSelector(selectHasError);
  const dispatch = useAppDispatch();
  const [hasLoginError, setHasLoginError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const savedEmail = getUserEmail()?.email;
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: savedEmail || '',
      password: '',
      rememberMe: Boolean(savedEmail),
    },
    onSubmit: (values: EmailAuth) => {
      dispatch(logInWithEmail(values));
    },
  });

  // Redirects to the MS login page, for picking an account
  const handleLoginMs = () => {
    setHasLoginError(false);
    dispatch(setIsLoading(true));
    window.location.replace(`${API_BASE_URL}/microsoftauth/login?platform=admin`);
  };

  // Handle SSO callback success
  const dispatchLogin = () => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    if (token !== null) {
      // Get correct auth credentials and redirect to dashboard
      dispatch(logInWithExchange(token));
    }
  };

  // Redirects the user to the home page once it has been successfully authenticated
  const handleGoNext = () => {
    if (isAuthenticated) {
      navigate('/');
    }
  };

  // Handle SSO callback failures
  const handleFailure = () => {
    // eslint-disable-next-line no-console
    const query = new URLSearchParams(location.search);
    const status = query.get('status');
    if (status !== null) {
      switch (status) {
        case SSOLoginStatus.NotFound:
          setHasLoginError(true);
          setErrorMessage(SSOErrorMessage.NotFound);
          break;
        case SSOLoginStatus.Unauthorized:
          setHasLoginError(true);
          setErrorMessage(SSOErrorMessage.Unauthorized);
          break;
        default:
          setHasLoginError(true);
          setErrorMessage(`Unknown error: ${status}`);
          break;
      }
    }
  };

  // Triggers a toast notification in case of an auth error
  const handleToastNotification = () => {
    if (hasLoginError && errorMessage) {
      setSnackPack((prev) => [
        ...prev,
        { customErrorMessage: errorMessage, message: '', successful: false, key: new Date().getTime() },
      ]);
    }
  };

  // Sets the error states that will trigger the handle toast notification function
  useEffect(() => {
    if (authFailed) {
      setHasLoginError(true);
      setErrorMessage(`Invalid email or password`);
    }
  }, [authFailed]);
  // Reset session error state on load
  useEffect(() => {
    dispatch(setHasError(false));
  }, [dispatch]);
  useEffect(dispatchLogin, [location, dispatch]);
  useEffect(handleGoNext, [isAuthenticated, navigate]);
  useEffect(handleFailure, [location]);
  useEffect(handleToastNotification, [hasLoginError, setSnackPack, errorMessage]);

  return (
    <ThemeProvider theme={azulDesign}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '0.75fr 1fr',
          height: '100vh',
          justifyContent: 'center',
          margin: '0',
          width: '100vw',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: backgroundWhite,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            margin: '0',
          }}
        >
          <img alt='The Ksquare Group Logo' src={TKGLogo} style={{ margin: '-1.5rem 0 3.75rem 0' }} width='50%' />
          <img alt='Dashground Login' src={loginImg} width='67%' />
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: backgroundFocusBlue,
            display: 'flex',
            justifyContent: 'center',
            margin: '0',
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              margin: '0',
              width: '282px',
            }}
          >
            <Typography variant='loginHeader'>Welcome to</Typography>
            <Typography
              sx={{
                fontSize: '2rem',
                lineHeight: '40px',
                marginBottom: '24px',
              }}
              variant='loginHeader'
            >
              Dash<span style={{ color: primaryHoverBlue2 }}>ground</span>
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                label='Email'
                name='email'
                sx={{
                  margin: '1.5rem 0',
                  '& .MuiOutlinedInput-input': {
                    padding: '0.75rem 1rem',
                  },
                }}
                type='email'
                value={formik.values.email}
                variant='outlined'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              <TextField
                fullWidth
                label='Password'
                name='password'
                sx={{
                  marginBottom: '1.5rem',
                  '& .MuiOutlinedInput-input': {
                    padding: '0.75rem 1rem',
                  },
                }}
                type='password'
                value={formik.values.password}
                variant='outlined'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  margin: '1rem 0',
                  width: '100%',
                }}
              >
                <Typography variant='paragraph12'>
                  {' '}
                  <Checkbox
                    checked={formik.values.rememberMe}
                    checkedIcon={<CheckboxIcon />}
                    disableRipple
                    icon={<CheckboxOutlineIcon />}
                    name='rememberMe'
                    sx={{ padding: '0 5px 2px 3px' }}
                    onChange={formik.handleChange}
                  />{' '}
                  Remember Me
                </Typography>
                <a aria-label='forgot password?' href='https://www.google.com/'>
                  <Typography variant='link'>Forgot password?</Typography>
                </a>
              </Box>
              <Button fullWidth sx={{ margin: '1rem 0' }} type='submit' variant='azulPrimary'>
                Login
              </Button>
            </form>
            <Typography margin='1.5rem 0' variant='paragraph12'>
              Or join with your existing account
            </Typography>
            <Button startIcon={<MSLogo />} variant='microsoftLogin' onClick={handleLoginMs}>
              Sign in with Microsoft
            </Button>
          </Box>
        </Box>
      </Box>
      <SimpleSnackbar />
    </ThemeProvider>
  );
};
export default Login;
