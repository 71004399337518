/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, PropsWithChildren, ReactNode } from 'react';
import { accentBlue, focusPurple, paragraphPlaceholder } from 'styles/partials/variables';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import { ReactComponent as CloseIcon } from '../../assets/Close.svg';
import { azulDesign } from './AzulDesignTheme';

type AzulModalProps = {
  open: boolean;
  modalActions?: ReactNode;
  modalContent?: ReactNode;
  modalContentPadding?: string;
  padding?: string;
  subtitle?: string;
  title?: string;
  onClose?: () => void;
};

const AzulModal: FC<PropsWithChildren<AzulModalProps>> = ({
  children,
  modalActions,
  modalContent,
  modalContentPadding,
  open,
  padding,
  subtitle,
  title = 'Modal Title',
  onClose,
}: PropsWithChildren<AzulModalProps>) => (
  <ThemeProvider theme={azulDesign}>
    {/* The node that triggers the modal/dialog */}
    {children}

    {/* The main container for the modal */}
    <Dialog
      aria-labelledby='dialog-title'
      open={open}
      sx={{ '& .MuiPaper-root': { padding } }}
      transitionDuration={0}
      onClose={onClose}
    >
      {/* The icon for closing the modal */}
      {onClose && (
        <IconButton
          TouchRippleProps={{ style: { color: accentBlue } }}
          aria-label='close'
          disableTouchRipple
          sx={{
            // color: (theme) => theme.palette.grey[500],
            padding: '.75rem',
            position: 'absolute',
            right: 16,
            top: 16,
            '&:hover': {
              backgroundColor: accentBlue,
            },
            '&:focus-visible': {
              borderRadius: '8px',
              outline: `2px solid ${focusPurple}`,
            },
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}

      {/* The modal title */}
      <DialogTitle id='dialog-title'>{title}</DialogTitle>

      <Typography
        sx={{
          display: subtitle ? '' : 'none',
          color: paragraphPlaceholder,
          letterSpacing: '0.004em',
          padding: '0.5rem 0 3.5rem 0',
          textAlign: 'left',
        }}
        variant='paragraph'
      >
        {subtitle}
      </Typography>

      {/* The modal main content */}
      {modalContent && (
        <DialogContent
          sx={{
            minHeight: '20vh',
            maxHeight: '40vh',
            padding: `${modalContentPadding} !important`,
          }}
        >
          {modalContent}
        </DialogContent>
      )}

      {/* The modal actions */}
      {modalActions && <DialogActions>{modalActions}</DialogActions>}
    </Dialog>
  </ThemeProvider>
);

export default AzulModal;
