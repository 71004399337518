/* eslint-disable @typescript-eslint/no-shadow */
import dayjs from 'dayjs';
import _ from 'lodash';
import { useState, FC, ChangeEvent, MouseEvent, useEffect } from 'react';
import { getCategoryLabel } from 'utils/constants/announcementCategories';
import { PublishingStatus } from 'utils/constants/publishingStatus';
import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  ThemeProvider,
  Box,
  TablePagination,
} from '@mui/material';
import { ReactComponent as BlueStatus } from '../../../assets/EllipseBlue.svg';
import { ReactComponent as GreenStatus } from '../../../assets/EllipseGreen.svg';
import { ReactComponent as YellowStatus } from '../../../assets/EllipseYellow.svg';
import { Announcement } from '../../../type/announcement';
import MoreSettingsBtn from '../../moreSettingsBtn/MoreSettingsBtn';
import { azulDesign } from '../../simpleComponents/AzulDesignTheme';
import AzulPagination from '../../simpleComponents/AzulPagination';

type StyledAnnouncementTableProps = {
  audienceFilters?: string[]; // Selected audience members or groups to filter by
  audienceType: 'groups' | 'individuals';
  categoriesFilters?: {
    // Selected categories to filter by
    'Area Info Related': boolean;
    Community: boolean;
    Training: boolean;
    Activities: boolean;
    Social: boolean;
    'For All': boolean;
  };
  count: number; // The lenght of the data, equivalent to the number of rows
  data: Announcement[]; // The fetched announcements' data for the current group
  dateFilters?: number[]; // Selected date range to filter by
  statusFilters?: {
    // Selected statuses to filter by
    published: boolean;
    scheduled: boolean;
    draft: boolean;
  };
};

// The table receives the data to display, and the count is the number of rows, or data.length
export const StyledAnnouncementTable: FC<StyledAnnouncementTableProps> = ({
  audienceFilters,
  audienceType,
  categoriesFilters,
  count,
  data,
  dateFilters,
  statusFilters,
}: StyledAnnouncementTableProps) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Function that returns only the keys that have true values in the statusFilters object
  const checkedStatus: string[] = _(statusFilters).reduce((checkedStatus: string[], value, key) => {
    if (value === true) checkedStatus.push(key);
    return checkedStatus;
  }, []);

  // Function that returns only the keys that have true values in the categoriesFilters object
  const checkedCategories: string[] = _(categoriesFilters).reduce((checkedCategories: string[], value, key) => {
    if (value === true) checkedCategories.push(key);
    return checkedCategories;
  }, []);

  // =================================================  F I L T E R I N G  ======================================================

  // Passing the incoming data through each of the 4 filtering options and returning the result every time
  data = checkedCategories.length ? data.filter(({ category }) => checkedCategories.includes(category.name)) : data;
  data = audienceFilters?.length
    ? data.filter(({ AudienceAnnouncement }) => audienceFilters.includes(AudienceAnnouncement[0].fullName))
    : data;
  data = dateFilters?.length
    ? data.filter(({ date }) => dateFilters[0] <= dayjs(date).valueOf() && dayjs(date).valueOf() <= dateFilters[1])
    : data;
  data = checkedStatus.length ? data.filter(({ status }) => checkedStatus.includes(status)) : data;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

  // Changes the layout when changing page number
  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null | ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  // Calculates the number of rows on each page
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Goes to the first page when data changes
  useEffect(() => {
    setPage(0);
  }, [data]);

  function handleStatusColor(announcement: Announcement): import('react').ReactNode {
    if (announcement.status === PublishingStatus.Scheduled) {
      return <YellowStatus />;
    }
    if (announcement.status === PublishingStatus.Published) {
      return <GreenStatus />;
    }
    return <BlueStatus />;
  }

  return (
    <ThemeProvider theme={azulDesign}>
      {/* The main table container */}
      <TableContainer component={Paper}>
        <Table aria-label='custom pagination table'>
          {/* The table's first row with the column titles */}
          <TableHead>
            <TableRow>
              <TableCell>NAME</TableCell>
              <TableCell>CATEGORY</TableCell>
              <TableCell>AUDIENCE</TableCell>
              <TableCell>DATE</TableCell>
              <TableCell>DURATION</TableCell>
              <TableCell>STATUS</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          {/* Where the searched announcements data information is actually displayed */}
          <TableBody>
            {(rowsPerPage > 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data).map(
              (announcement) => {
                const annAudience: string[] = [];
                const { AudienceAnnouncement, id, name, categoryId, date, duration, status } = announcement;
                AudienceAnnouncement.forEach(({ group, fullName }) => {
                  if (group) {
                    if (!annAudience.includes(group.name)) {
                      annAudience.push(group.name);
                    }
                  } else if (!annAudience.includes(fullName)) {
                    annAudience.push(fullName);
                  }
                });
                return (
                  <TableRow key={id}>
                    <TableCell sx={{ minWidth: '5rem' }}>{name}</TableCell>
                    <TableCell>{getCategoryLabel(categoryId)}</TableCell>
                    <TableCell sx={{ minWidth: '13rem' }}>{annAudience.join(', ')}</TableCell>
                    <TableCell sx={{ minWidth: '5.5rem' }}>{date}</TableCell>
                    <TableCell>{duration && `${duration} ${duration === 1 ? 'day' : 'days'}`}</TableCell>
                    <TableCell sx={{ display: 'flex', alignItems: 'center', position: 'relative', top: '5px' }}>
                      {handleStatusColor(announcement)}
                      <Typography
                        component='span'
                        sx={{ marginLeft: '.5rem' }}
                      >{`${status[0].toUpperCase()}${status.slice(1)}`}</Typography>
                    </TableCell>
                    <TableCell align='right'>
                      <MoreSettingsBtn audienceType={audienceType} blueStyle crudId={id} label='Announcement' />
                    </TableCell>
                  </TableRow>
                );
              },
            )}
            {count === 0 ||
              (data.length === 0 && (
                <TableRow style={{ height: 64 * emptyRows }}>
                  <TableCell align='center' colSpan={7}>
                    No results found
                  </TableCell>
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 64 * emptyRows }}>
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* The above table's pagination with a custom styling */}
      <Box alignItems='center' display='grid' gridTemplateColumns='2fr 1fr' justifyItems='right' mt='24px' width='100%'>
        <AzulPagination
          currentPage={page}
          numberOfPages={Math.ceil(count / rowsPerPage)}
          onPageChange={handleChangePage}
        />
        <TablePagination
          ActionsComponent='noindex'
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: false,
            IconComponent: KeyboardArrowDown,
          }}
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: count }]}
          sx={{ width: 1100 }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </ThemeProvider>
  );
};
