import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ThemeProvider } from '@mui/material';
import { azulDesign } from './AzulDesignTheme';
import AzulModal from './AzulModal';

type AzulConfirmNavigationModalProps = {
  open: boolean;
  navigateTo: string;
  subtitle?: string;
  title?: string;
  modalActions?: ReactNode;
  modalContent?: ReactNode;
  saveChangesDisabled?: boolean;
  onClose?: () => void;
  saveChanges?: () => void;
};

const AzulConfirmNavigationModal = ({
  open,
  navigateTo,
  subtitle = 'Changes will be lost if you go back without saving.',
  title = 'Are you sure you want to leave?',
  modalActions,
  modalContent,
  saveChangesDisabled,
  onClose,
  saveChanges,
}: AzulConfirmNavigationModalProps) => {
  const navigate = useNavigate();

  return (
    <AzulModal
      modalActions={
        modalActions || (
          <ThemeProvider theme={azulDesign}>
            <Button variant='azulSecondary' onClick={() => navigate(navigateTo)}>
              Leave Without Saving
            </Button>

            {saveChanges ? (
              <Button disabled={saveChangesDisabled} variant='azulPrimary' onClick={saveChanges}>
                Save Changes
              </Button>
            ) : (
              <Button variant='azulPrimary' onClick={onClose}>
                Cancel
              </Button>
            )}
          </ThemeProvider>
        )
      }
      modalContent={modalContent}
      open={open}
      padding='3.5rem'
      subtitle={subtitle}
      title={title}
      onClose={onClose}
    />
  );
};

export default AzulConfirmNavigationModal;
