import { FC, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ThemeProvider } from '@mui/material';
import { ReactComponent as BackIcon } from '../../assets/BackArrow.svg';
import { primaryBlue, disabledGray2 } from '../../styles/partials/variables';
import { azulDesign } from '../simpleComponents/AzulDesignTheme';
import AzulModal from '../simpleComponents/AzulModal';

type FormConfirmationhModalProps = {
  isFormByGroup: boolean; // Determines the form's audience type to help redirect to the correct table tab when leaving/completing the form
  isServiceCreate: boolean; // Determines the service (edit or create) that will be triggered through the buttons inside the modal
  label: 'Background' | 'Announcement'; // The general label for the modal, so you know what are you publishing, saving or editing
  primaryActionLabel: string; // The label for the primary button
  secondaryActionLabel: string; // The label for the secondary button
  isSubmitting?: boolean; // To determine if a request is loading and hence disable the buttons
  leaveForm?: boolean; // passed for the case when we click the back arrow button -> has "leave without saving" or "save" options
  primaryDisabled?: boolean; // Determines when is the primary button clickable or not
  secondaryDisabled?: boolean; // Determines when is the secondary button clickable or not
  onPublish: (isDraft: boolean) => void; // Callback function for triggering the publish service outside of this component
};

const FormConfirmationhModal: FC<FormConfirmationhModalProps> = ({
  isFormByGroup,
  isServiceCreate,
  label,
  primaryActionLabel,
  secondaryActionLabel,
  isSubmitting,
  leaveForm,
  primaryDisabled,
  secondaryDisabled,
  onPublish,
}: FormConfirmationhModalProps) => {
  /* States */
  const [open, setOpen] = useState<boolean>(false);
  const [actionLabel, setActionLabel] = useState<string>('');
  const [isPrimaryContent, setIsPrimaryContent] = useState<boolean>(true); // to determine the type of content the modal will render
  const navigate = useNavigate();

  const modalTitle = {
    publish: `${label} Publish Confirmation`,
    save: isServiceCreate ? `Save ${label} as Draft` : `Save changes in ${label}`,
    leave: `Are you sure you want to leave?`,
  };

  const modalHelperText = {
    publish: `Are you sure you want to publish this ${label.toLowerCase()}?`,
    save: isServiceCreate
      ? `Are you sure you want to save this ${label.toLowerCase()} as a draft? ${'\n'} Drafts will not be published`
      : `Are you sure you want to save changes?`,
    leave: `Changes will be lost if you go back without saving`,
  };

  /* Handlers for opening and closing the Modal */
  const handleOpen = (toDo: string, isPrimaryBtn: boolean) => {
    setOpen(true);
    setActionLabel(toDo);
    setIsPrimaryContent(leaveForm ? false : isPrimaryBtn);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // Helps choose the text to be displayed inside the modal acoording to the clicked button
  const chooseTitle = () => {
    let title: string;
    if (leaveForm) {
      title = modalTitle.leave;
    } else {
      title = isPrimaryContent ? modalTitle.publish : modalTitle.save;
    }
    return title;
  };

  const chooseHelperText = () => {
    let helperText: string;
    if (leaveForm) {
      helperText = modalHelperText.leave;
    } else {
      helperText = isPrimaryContent ? modalHelperText.publish : modalHelperText.save;
    }
    return helperText;
  };

  return (
    <ThemeProvider theme={azulDesign}>
      <AzulModal
        modalActions={
          // Buttons inside the modal, to trigger the final actions and callbacks
          <Fragment>
            <Button
              variant='azulSecondary'
              onClick={() => {
                if (leaveForm) navigate(`/background${isFormByGroup ? '' : '#individual'}`); // redirects to correct table tab after leaving
                handleClose();
              }}
            >
              {leaveForm ? 'Leave Without Saving' : 'Cancel'}
            </Button>
            <Button
              disabled={isSubmitting || (leaveForm && secondaryDisabled)}
              variant='azulPrimary'
              onClick={() => {
                onPublish(!isPrimaryContent);
                handleClose();
              }}
            >
              {actionLabel}
            </Button>
          </Fragment>
        }
        open={open}
        subtitle={chooseHelperText()}
        title={chooseTitle()}
        onClose={handleClose}
      >
        {/* The buttons that launch the modals (with primary or secondary content) */}
        {leaveForm ? (
          <Button
            disabled={isSubmitting}
            variant='azulBackLink'
            onClick={() => {
              handleOpen(secondaryActionLabel, false);
            }}
          >
            <BackIcon fill={isSubmitting ? disabledGray2 : primaryBlue} /> <span>&emsp;</span> Back
          </Button>
        ) : (
          <Fragment>
            <Button
              disabled={isSubmitting || secondaryDisabled}
              variant='azulSecondary'
              onClick={() => {
                handleOpen(secondaryActionLabel, false);
              }}
            >
              {secondaryActionLabel}
            </Button>
            <Button
              disabled={isSubmitting || primaryDisabled}
              variant='azulPrimary'
              onClick={() => {
                handleOpen(primaryActionLabel, true);
              }}
            >
              {primaryActionLabel}
            </Button>
          </Fragment>
        )}
      </AzulModal>
    </ThemeProvider>
  );
};

export default FormConfirmationhModal;
