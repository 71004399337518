import { useRef, useEffect } from 'react';
import { TemplateDraft } from 'type/background';
import { TextCoordinates, TextBoxCoords } from 'type/textBox';
import { useAppSelector } from './storeHooks';

const useDragger = (itemId: string, textCoords: ({ x, y }: TextCoordinates) => void, boxPadding = 0) => {
  const isClicked = useRef<boolean>(false);
  const isTitle = itemId === 'title';
  const templateDraft = useAppSelector<TemplateDraft | null | undefined>((state) => state.background.templateDraft);
  // Coordinates must match the css absolute position offset of the components inside the canvas
  const initialTextCoords = {
    startX: 250,
    startY: 150,
    lastX: (isTitle ? templateDraft?.titleBox?.x : templateDraft?.subtitleBox?.x) || 250,
    lastY: (isTitle ? templateDraft?.titleBox?.y : templateDraft?.subtitleBox?.y) || 150,
  };
  const coords = useRef<TextBoxCoords>(initialTextCoords);

  useEffect(() => {
    // The specific component to manipulate
    const target = document.getElementById(itemId);
    if (!target) throw new Error("Element with given id doesn't exist");

    // The parent container that holds and bounds the draggable component. Coordinates start in (0,0)
    const container = target.parentElement;
    if (!container) throw new Error('Target element must have a parent');

    // =========================  D R A G G I N G  =========================
    const onMouseDown = (e: MouseEvent) => {
      isClicked.current = true;
      coords.current.startX = e.clientX;
      coords.current.startY = e.clientY;
    };

    const onMouseMove = (e: MouseEvent) => {
      if (!isClicked.current) return;
      // To set the target's coordinates to where the mouse is moving
      const nextX = e.clientX - coords.current.startX + coords.current.lastX;
      const nextY = e.clientY - coords.current.startY + coords.current.lastY;
      target.style.left = `${nextX}px`;
      target.style.top = `${nextY}px`;

      // To void the text box from going outside the canvas boundaries
      const offsetX = e.clientX - parseInt(window.getComputedStyle(target).left, 10);
      const offsetY = e.clientY - parseInt(window.getComputedStyle(target).top, 10);
      let top = e.clientY - offsetY;
      let left = e.clientX - offsetX;

      // Checking when are the top and left coordinates of the box surpassing the container's top-left boundary (0,0) and assigning a new adjusted value
      // Subtracting or adding an extra amount because of the boxPadding we want to hide too
      if (top < 0 - boxPadding) top = -boxPadding;
      if (left < 0 - boxPadding) left = -boxPadding;
      if (top > container.clientHeight - target.clientHeight + boxPadding)
        top = container.clientHeight - target.clientHeight + boxPadding;
      if (left > container.clientWidth - target.clientWidth + boxPadding)
        left = container.clientWidth - target.clientWidth + boxPadding;

      target.style.top = `${top}px`;
      target.style.left = `${left}px`;
    };

    const onMouseUp = () => {
      isClicked.current = false;

      coords.current.lastX = target.offsetLeft;
      coords.current.lastY = target.offsetTop;
      textCoords({ x: coords.current.lastX, y: coords.current.lastY });
    };

    target.addEventListener('mousedown', onMouseDown);
    target.addEventListener('mouseup', onMouseUp);
    container.addEventListener('mousemove', onMouseMove);
    container.addEventListener('mouseleave', onMouseUp);

    const cleanup = () => {
      target.removeEventListener('mousedown', onMouseDown);
      target.removeEventListener('mouseup', onMouseUp);
      container.removeEventListener('mousemove', onMouseMove);
      container.removeEventListener('mouseleave', onMouseUp);
    };

    return cleanup;
  }, [boxPadding, isTitle, itemId, textCoords, isClicked]);
};

export default useDragger;
