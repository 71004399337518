import { FC, MouseEvent, useMemo, useState } from 'react';
import { TemplateDraft } from 'type/background';
import { DraggableTextBoxProps } from 'type/textBox';
import { TextBox } from 'utils/constants/canvas';
import { useAppSelector } from 'utils/hooks/storeHooks';
import { Box, Typography } from '@mui/material';
import deleteImageButton from '../../assets/deleteImgBtn.png';
import useDragger from '../../utils/hooks/useDragger';
import styles from './ResizableAndDraggable.module.scss';

const DraggableTextBox: FC<DraggableTextBoxProps> = ({
  activeColorEditing,
  textColor,
  textCoordinates,
  textId,
  onClickTextBox,
  onDelete,
}: DraggableTextBoxProps) => {
  const templateDraft = useAppSelector<TemplateDraft | null | undefined>((state) => state.background.templateDraft);
  const isTitle = textId === TextBox.Title;
  const inputVariant = isTitle ? 'canvasTitle' : 'canvasSubtitle';
  const boxPadding = 16;
  const defaultText = `Enter your ${textId}`;
  const [textBoxContent, setTextBoxContent] = useState(defaultText);
  const [textCoords, setTextCoords] = useState({ x: 250, y: 150 });

  // brings info from the GET request to show where they were placed last save
  useMemo(() => {
    if (templateDraft) {
      const { titleBox, subtitleBox } = templateDraft;
      if (isTitle && titleBox) {
        const { x, y, textContent } = titleBox;
        setTextCoords({ x, y });
        if (textContent) setTextBoxContent(textContent);
      } else if (!isTitle && subtitleBox) {
        const { x, y, textContent } = subtitleBox;
        setTextCoords({ x, y });
        if (textContent) setTextBoxContent(textContent);
      }
    }
  }, [isTitle, templateDraft]);

  // Custom hook for dragging components and obtaining its coordinates
  useDragger(
    textId,
    (newCoords) => {
      textCoordinates(newCoords);
    },
    boxPadding,
  );

  // For "deleting" the textbox by hiding it from the display through a callback
  function hideTextBox(event: MouseEvent<HTMLElement>) {
    event.stopPropagation();
    onDelete();
  }

  const handleClickTextBox = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onClickTextBox();
  };

  return (
    <Box
      className={styles.outerBox}
      id={textId}
      sx={{ left: textCoords.x, top: textCoords.y }}
      onClick={handleClickTextBox}
    >
      {/* The text input must stay as span because of its content editable property */}
      <Typography
        className={`${styles.inputBox} ${activeColorEditing && styles.activeColorEditingInputBox}`}
        color={textColor}
        component='span'
        contentEditable
        suppressContentEditableWarning
        variant={inputVariant}
      >
        {textBoxContent}
      </Typography>
      <button className={styles.deleteInput} onClick={(e) => hideTextBox(e)}>
        <img alt='Delete img icon' src={deleteImageButton} />
      </button>
    </Box>
  );
};

export default DraggableTextBox;
