/* eslint-disable no-restricted-syntax */

/* eslint-disable no-await-in-loop */
import { ColorPicker } from 'components/colorPicker/ColorPicker';
import AzulLoadingState from 'components/simpleComponents/AzulLoadingState';
import { sha256 } from 'crypto-hash';
import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import utc from 'dayjs/plugin/utc';
import html2canvas from 'html2canvas';
import _ from 'lodash';
import { FC, useState, useEffect, SyntheticEvent, useContext, useMemo, Fragment, MouseEvent } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Background, TemplateDraft } from 'type/background';
import { ImgProps } from 'type/canvas';
import { TextColors, TextCoordinates } from 'type/textBox';
import { AudienceType } from 'utils/constants/audienceType';
import { PublishingStatus } from 'utils/constants/publishingStatus';
import { Services } from 'utils/constants/services';
import { v4 as uuidv4 } from 'uuid';
import { Box, Button, ThemeProvider, SelectChangeEvent, Typography } from '@mui/material';
import { ReactComponent as ArrowDownIcon } from '../../assets/ArrowDownGray.svg';
import BackgroundPlaceholder from '../../assets/BackgroundPlaceholder.jpeg';
import Canvas from '../../components/canvas/Canvas';
import AddImageButton from '../../components/createBackgroundModal/CreateBackgroundModal';
import FormConfirmationModal from '../../components/formConfirmationModal/FormConfirmationModal';
import DragAndResizeImages from '../../components/resizableAndDraggable/DragAndResizeImages';
import AzulDatePicker from '../../components/simpleComponents/AzulDatePicker';
import { azulDesign } from '../../components/simpleComponents/AzulDesignTheme';
import AzulInput from '../../components/simpleComponents/AzulInput';
import AzulSelect from '../../components/simpleComponents/AzulSelect';
import SimpleSnackbar from '../../components/snackbar/Snackbar';
import { createFileFromUrl, fileService, uploadDraftImagesToS3 } from '../../services/fileService';
import {
  clearSelectedBackground,
  postNewBackground,
  readBackgroundById,
  updateBackground,
} from '../../store/features/backgroundSlice';
import snackContext from '../../store/features/contextSnackbar';
import { readAllGroups } from '../../store/features/groupSlice';
import { readAllUsers } from '../../store/features/userSlice';
import { backgroundWhite } from '../../styles/partials/variables';
import { Group } from '../../type/group';
import { SynkronUser } from '../../type/user';
import { DURATIONS } from '../../utils/constants/durationsAndTimezones';
import { useAppDispatch, useAppSelector } from '../../utils/hooks/storeHooks';
import styles from './BackgroundForm.module.scss';

type BackgroundFormProps = {
  audienceType: AudienceType.Group | AudienceType.Individual; // Handles if the service is for Group or Individual Announcements
  service?: 'create' | 'edit'; // The operation to use with the form info
};

const BackgroundForm: FC<BackgroundFormProps> = ({ audienceType, service = 'create' }: BackgroundFormProps) => {
  //  ===============================  H O O K S  ===============================
  const dispatch = useAppDispatch();
  const { setSnackPack } = useContext(snackContext);
  const navigate = useNavigate();
  const location = useLocation(); // for receiving values from other views sent through useNavigate
  const params = useParams(); // grabs the path's params we're interested on -> The id of the group to edit
  const backgroundSelected = useAppSelector<Background | null | undefined>(
    (state) => state.background.backgroundSelected,
  );
  const isBackgroundLoading = useAppSelector<boolean>((state) => state.background.loading);

  //  =============================== BG PROPS  ===============================
  const isBackgroundByGroup = audienceType === AudienceType.Group;
  const isServiceCreate = service === Services.Create;
  const isBackgroundPublished = backgroundSelected?.status === PublishingStatus.Published;
  const isBackgroundScheduled = backgroundSelected?.status === PublishingStatus.Scheduled;
  const backgroundId = Number(params.id);
  dayjs.extend(utc);
  dayjs.extend(isToday);

  //  ===============================  C A N V A S   &   I M A G E S  ===============================
  let fileReader: FileReader; // to read the bg img file that will be hashed
  const canvas = document.getElementById('canvas');
  const titleText = document.getElementById('title')?.innerText || 'Enter your title';
  const subtitleText = document.getElementById('subtitle')?.innerText || 'Enter your subtitle';
  const [colorValue, setColorValue] = useState(backgroundWhite);
  const [textColorsObject, setTextColorsObject] = useState<TextColors>({ title: '#323232', subtitle: '#323232' });
  const [titleCoordinatesObject, setTitleCoordinatesObject] = useState<TextCoordinates>({ x: 250, y: 150 });
  const [subtitleCoordinatesObject, setSubtitleCoordinatesObject] = useState<TextCoordinates>({ x: 250, y: 150 });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showTextTools, setShowTextTools] = useState(false);
  const [imagesArr, setImagesArr] = useState<ImgProps[]>([]); // the images to be added to the canvas through the modal
  const [draftImagesArr, setDraftImagesArr] = useState<ImgProps[]>([]); // all the current draft imgs array on the canvas
  const [changeInCanvasImgs, setChangeInCanvasImgs] = useState<boolean>(false);
  const [imagesArrCount, setImagesArrCount] = useState<number>(0); // counter to track the number of images added to the canvas, for validation of save and publish btns
  const [customBackgroundImgUrl, setCustomBackgroundImgUrl] = useState<string>();
  const [customBackgroundImgId, setCustomBackgroundImgId] = useState<number | undefined>(undefined);
  const open = Boolean(anchorEl);
  const idColorPicker = open ? 'background-color-picker-popover' : undefined;
  const importedBackgroundImgUrl: string = location.state?.url;
  const [editDisabled, setEditDisabled] = useState(Boolean(location.state?.noEdit));
  const isCanvasEditable = backgroundSelected ? backgroundSelected.editableCanvas : !editDisabled; // canvas edit chips are disabled ONLY when coming from the import an image option, enabled for creatingg or on editing a (published) bg

  // Saves information about the url of the imported image on create mode and passed to this view through a use navigate
  useMemo(() => {
    if (importedBackgroundImgUrl && importedBackgroundImgUrl !== customBackgroundImgUrl) {
      setCustomBackgroundImgUrl(importedBackgroundImgUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importedBackgroundImgUrl]);

  //  ===============================  F O R M   V A L U E S  ===============================

  const today = dayjs().utc();
  const todayDate = today.format('YYYY-MM-DD');
  const MAX_TODAY_PUBLISHING_TIME = dayjs.utc(`${todayDate}T23:30:00+00:00`);
  const MAX_NAME_LENGTH = 100;
  const avialableAudience: Array<Group | SynkronUser> = useAppSelector((state) =>
    isBackgroundByGroup ? state.group.groups : state.user.users,
  );
  const [selectedAudienceIdsArr, setSelectedAudienceIdsArr] = useState<number[]>([]);
  const [backgroundName, setBackgroundName] = useState<string>('');
  const [selectedDuration, setSelectedDuration] = useState<string>('');
  const [date, setDate] = useState<Dayjs | undefined>(isServiceCreate ? undefined : today);
  const [isNameInvalid, setIsNameInvalid] = useState<boolean>(false);
  const [isDateInvalid, setIsDateInvalid] = useState<boolean>(false);
  const [isLoadingScreen, setIsLoadingScreen] = useState<boolean>(false);
  const [isEditDurationInvalid, setIsEditDurationInvalid] = useState<boolean>(false);
  const [isPublishDisabled, setIsPublishDisabled] = useState<boolean>(true);
  const [isSaveDraftDisabled, setIsSaveDraftDisabled] = useState<boolean>(true);
  const [isSaveChangesDisabled, setIsSaveChangesDisabled] = useState<boolean>(true);
  const [isTitleChipDisabled, setIsTitleChipDisabled] = useState<boolean>(false);
  const [isSubtitleChipDisabled, setIsSubtitleChipDisabled] = useState<boolean>(false);
  const [hideTitleBox, setHideTitleBox] = useState(true);
  const [hideSubtitleBox, setHideSubtitleBox] = useState(true);
  const [selectedAudienceArr, setSelectedAudienceArr] = useState<string[]>([]);
  const [previousAudience, setPreviousAudience] = useState<string[]>([]);

  // Sets the information about the audience of the current canvas details before editing
  useMemo(() => {
    const oldAudience: string[] = [];
    if (backgroundSelected) {
      const { AudienceBackground, draftData, image, status } = backgroundSelected;
      AudienceBackground.forEach(({ fullName, group }) => {
        if (group) {
          if (!oldAudience.includes(group.name)) {
            oldAudience.push(group.name);
          }
        } else if (!oldAudience.includes(fullName)) {
          oldAudience.push(fullName);
        }
      });
      setPreviousAudience(oldAudience);
      // Once the canvas draft was published (bg img uploaded to s3) the canvas won't be editable anymore, but the rest of the form will
      if (status === 'published') setEditDisabled(true);
      if (image) setCustomBackgroundImgId(image);
      // Reconstructs the draft with its components and each of their properties ONLY when there's no backgroung img already associated
      if (draftData && status !== 'published') {
        const { backgroundColor, titleBox, subtitleBox, imagesArray } = draftData;
        if (titleBox && subtitleBox) {
          setTitleCoordinatesObject({ x: titleBox.x, y: titleBox.y });
          setSubtitleCoordinatesObject({ x: subtitleBox.x, y: subtitleBox.y });
          if (!subtitleBox.hidden) setHideSubtitleBox(false);
          if (!titleBox.hidden) setHideTitleBox(false);
        }
        if (backgroundColor) setColorValue(backgroundColor);
        if (imagesArray?.length) {
          setImagesArr(imagesArray);
        }
      }
    }
  }, [backgroundSelected]);

  // =============================  F O R M  B T N S  V A L I  D A T I O N  =============================

  // For the isSaveChangesDisabledValidation
  useEffect(() => {
    // We want to make a comparison between the saved canvas' images array in the BE and the one the user interacts with in the FE.
    // We check for diffrences in keys like coordinates and sizes in the ImgProps[{}], while ignoring the urlS3 key
    // Reason: urlS3 key is changed for proper upload of the images (solving the tainted canvas bug)
    if (draftImagesArr.length > 0 && backgroundSelected?.draftData?.imagesArray) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < draftImagesArr.length; i++) {
        const backEndData = backgroundSelected?.draftData?.imagesArray[i];
        const frontEndData = draftImagesArr[i];
        // create a deep copy where it removes the urls3 key
        const backEndDataCopy = _.omit(backEndData, 'urlS3');
        const frontEndDatapCopy = _.omit(frontEndData, 'urlS3');
        // to compare the objects contents we must stringify them
        setChangeInCanvasImgs(JSON.stringify(backEndDataCopy) !== JSON.stringify(frontEndDatapCopy));
      }
    }
  }, [backgroundSelected?.draftData?.imagesArray, draftImagesArr]);

  const isSaveDraftDisabledValidation =
    (selectedAudienceArr.length && backgroundName.length) ||
    Number(selectedDuration) ||
    !hideSubtitleBox ||
    !hideTitleBox ||
    Number(canvas?.childElementCount) > 3 ||
    imagesArr.length > 0;

  // All bgs MUST have a name and an audience, the rest is optional
  // Any other changes in the form or canvas (size, color, position, etc) will trigger the save changes btn
  const isSaveChangesDisabledValidation =
    selectedAudienceArr.length &&
    backgroundName.length &&
    ((customBackgroundImgId && backgroundSelected?.image !== customBackgroundImgId) ||
      backgroundSelected?.name !== backgroundName ||
      selectedAudienceArr.toString() !== previousAudience.toString() ||
      backgroundSelected?.duration !== Number(selectedDuration) ||
      (backgroundSelected?.date.toString() !== date?.toDate().toISOString().split('T')[0] && !isDateInvalid) ||
      (backgroundSelected.status !== 'published' &&
        (backgroundSelected?.draftData?.backgroundColor !== colorValue ||
          backgroundSelected?.draftData?.titleBox?.hidden === isTitleChipDisabled ||
          backgroundSelected?.draftData?.subtitleBox?.hidden === isSubtitleChipDisabled ||
          JSON.stringify(backgroundSelected?.draftData?.subtitleBox) !==
            JSON.stringify({
              hidden: hideSubtitleBox,
              textColor: textColorsObject.subtitle,
              textContent: subtitleText,
              type: 'subtitle',
              x: subtitleCoordinatesObject.x,
              y: subtitleCoordinatesObject.y,
            }) ||
          JSON.stringify(backgroundSelected?.draftData?.titleBox) !==
            JSON.stringify({
              hidden: hideTitleBox,
              textColor: textColorsObject.title,
              textContent: titleText,
              type: 'title',
              x: titleCoordinatesObject.x,
              y: titleCoordinatesObject.y,
            }) ||
          changeInCanvasImgs || // change in size, rotation and coordinates
          imagesArrCount !== backgroundSelected?.draftData?.imagesArray?.length))); // change in the amount of imgs

  const isPublishDisabledValidation =
    (isServiceCreate &&
      hideSubtitleBox &&
      hideTitleBox &&
      imagesArrCount === 0 &&
      !importedBackgroundImgUrl &&
      colorValue === '#ffffff') ||
    backgroundName.length < 1 ||
    isNameInvalid ||
    Number(selectedDuration) === 0 ||
    selectedAudienceArr.length === 0 ||
    !date ||
    isDateInvalid ||
    isBackgroundPublished || // no need to publish something that has already been published
    isBackgroundScheduled; // no need to publish something that has already been published as scheduled

  // =========================  C A N V A S   C U S T O M I Z A T I O N  =========================

  useEffect(() => {
    if (canvas) canvas.style.backgroundColor = colorValue;
  }, [canvas, colorValue]);

  // Removes the title or subtitle from the canvas when clicking their respective 'x' button
  const handleOnDeleteText = (hide: boolean, isTitle?: boolean) => {
    if (isTitle) {
      setHideTitleBox(hide);
      setIsTitleChipDisabled(!hide);
    } else {
      setHideSubtitleBox(hide);
      setIsSubtitleChipDisabled(!hide);
    }
  };

  // Adds a (sub)title when clicking the add (sub)title buttons
  const handleOnAddText = (isTitle?: boolean) => {
    if (canvas) {
      if (isTitle) {
        setHideTitleBox(false);
        setIsTitleChipDisabled(true);
      } else {
        setHideSubtitleBox(false);
        setIsSubtitleChipDisabled(true);
      }
    }
  };

  const handleClickChangeColor = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseBackgroundColorPicker = () => {
    setAnchorEl(null);
  };

  const handleTexColors = (colorsObject: TextColors) => {
    setTextColorsObject(colorsObject);
  };

  const handleTitleCoordinates = (coordsObject: TextCoordinates) => {
    setTitleCoordinatesObject(coordsObject);
  };

  const handleSubtitleCoordinates = (coordsObject: TextCoordinates) => {
    setSubtitleCoordinatesObject(coordsObject);
  };

  // ============================  C A N V A S  I M A G E S  ============================

  const loadImageToCanvas = async (imageUrl: string) => {
    const imageLoaded: ImgProps = {
      x: 50,
      y: 50,
      width: 300,
      height: 200,
      image: imageUrl,
      rotation: 0,
      skewX: 0,
      skewY: 0,
      id: uuidv4(), // generates a unique id for each img
    };
    setImagesArr((prevArr) => [...prevArr, imageLoaded]);
  };

  const convertCanvasToImageFile = async (fileName: string) => {
    if (!canvas) return createFileFromUrl(BackgroundPlaceholder, fileName);
    const newCanvas = await html2canvas(canvas, { allowTaint: false, useCORS: true, scale: 8 }); // high scale value for HD, above 5 looks decent
    const canvasBlob: Blob = await new Promise((resolve) => {
      newCanvas.toBlob(
        (blob) => {
          if (!blob) return;
          resolve(blob);
        },
        'image/jpeg',
        1,
      );
    });
    const newFile: File = new File([canvasBlob], `${fileName}.jpeg`, {
      type: 'image/jpeg',
    });
    return newFile;
  };

  // TODO: refactor these 2 CRUD handlers into 1
  const handleFileCRUD = async (finalDraftImagesArray: ImgProps[], backgroundImgId: number) => {
    let isSnackSuccessful = false;
    let snackMsg: string;
    // Updating a scheduled bg created from import an image (PATCH)
    if (
      isBackgroundScheduled &&
      !isCanvasEditable &&
      !isServiceCreate &&
      backgroundSelected &&
      backgroundImgId &&
      (selectedAudienceIdsArr || selectedAudienceArr)
    ) {
      const res = await dispatch(
        updateBackground({
          audience: isBackgroundByGroup ? selectedAudienceIdsArr : selectedAudienceArr,
          currentAudience: backgroundSelected.AudienceBackground,
          duration: Number(selectedDuration),
          id: backgroundId,
          name: backgroundName,
          status: PublishingStatus.Scheduled,
          date,
        }),
      );
      isSnackSuccessful = res.meta.requestStatus === 'fulfilled';
      snackMsg = 'The background has been updated';
    } else {
      const content = fileReader.result;
      const draftDataObj: TemplateDraft = {
        backgroundColor: colorValue,
        imagesArray: finalDraftImagesArray,
        titleBox: {
          hidden: hideTitleBox,
          textColor: textColorsObject.title,
          textContent: titleText,
          type: 'title',
          x: titleCoordinatesObject.x,
          y: titleCoordinatesObject.y,
        },
        subtitleBox: {
          hidden: hideSubtitleBox,
          textColor: textColorsObject.subtitle,
          textContent: subtitleText,
          type: 'subtitle',
          x: subtitleCoordinatesObject.x,
          y: subtitleCoordinatesObject.y,
        },
      };
      if (content) {
        const hash = await sha256(content);
        // Creating a new bg and publishing it straight away (POST)
        if (isServiceCreate && backgroundImgId && hash && (selectedAudienceIdsArr || selectedAudienceArr)) {
          const res = await dispatch(
            postNewBackground({
              name: backgroundName,
              image: backgroundImgId,
              status: PublishingStatus.Scheduled,
              audience: isBackgroundByGroup ? selectedAudienceIdsArr : selectedAudienceArr,
              checksum: hash,
              duration: Number(selectedDuration),
              date,
              draftData: draftDataObj,
              editableCanvas: isCanvasEditable,
            }),
          );
          isSnackSuccessful = res.meta.requestStatus === 'fulfilled';
          snackMsg = 'The background has been created';
        } // Updating a scheduled bg created from scratch canvas (PATCH)
        else if (
          !isServiceCreate &&
          backgroundSelected &&
          backgroundImgId &&
          hash &&
          (selectedAudienceIdsArr || selectedAudienceArr)
        ) {
          const res = await dispatch(
            updateBackground({
              audience: isBackgroundByGroup ? selectedAudienceIdsArr : selectedAudienceArr,
              currentAudience: backgroundSelected.AudienceBackground,
              checksum: hash,
              duration: Number(selectedDuration),
              image: backgroundImgId,
              id: backgroundId,
              name: backgroundName,
              status: PublishingStatus.Scheduled,
              date,
              draftData: draftDataObj,
              editableCanvas: isCanvasEditable,
            }),
          );
          isSnackSuccessful = res.meta.requestStatus === 'fulfilled';
          snackMsg = 'The background has been updated';
        }
      }
    }
    if (isSnackSuccessful) navigate(`/background${isBackgroundByGroup ? '' : '#individual'}`);
    setIsLoadingScreen(false);
    setSnackPack((prev) => [...prev, { message: snackMsg, successful: isSnackSuccessful, key: new Date().getTime() }]);
  };

  const handleDraftCRUD = async (finalDraftImagesArray: ImgProps[], importedImgId?: number) => {
    let isSnackSuccessful = false;
    let snackMsg: string;
    const draftDataObj: TemplateDraft = {
      backgroundColor: colorValue,
      imagesArray: finalDraftImagesArray,
      titleBox: {
        hidden: hideTitleBox,
        textColor: textColorsObject.title,
        textContent: titleText,
        type: 'title',
        x: titleCoordinatesObject.x,
        y: titleCoordinatesObject.y,
      },
      subtitleBox: {
        hidden: hideSubtitleBox,
        textColor: textColorsObject.subtitle,
        textContent: subtitleText,
        type: 'subtitle',
        x: subtitleCoordinatesObject.x,
        y: subtitleCoordinatesObject.y,
      },
    };
    // Creating a new bg and saving it as draft (POST)
    if (isServiceCreate && (selectedAudienceIdsArr || selectedAudienceArr)) {
      const res = await dispatch(
        postNewBackground({
          name: backgroundName,
          image: importedImgId,
          status: PublishingStatus.Draft,
          audience: isBackgroundByGroup ? selectedAudienceIdsArr : selectedAudienceArr,
          checksum: ' ',
          duration: Number(selectedDuration),
          date,
          draftData: draftDataObj,
          editableCanvas: isCanvasEditable,
        }),
      );
      isSnackSuccessful = res.meta.requestStatus === 'fulfilled';
      snackMsg = `The background has been saved as draft`;
    }
    // Updating a draft and saving changes (PATCH)
    else if (!isServiceCreate && backgroundSelected && (selectedAudienceIdsArr || selectedAudienceArr)) {
      const res = await dispatch(
        updateBackground({
          audience: isBackgroundByGroup ? selectedAudienceIdsArr : selectedAudienceArr,
          currentAudience: backgroundSelected.AudienceBackground,
          checksum: ' ',
          duration: Number(selectedDuration),
          image: importedImgId,
          id: backgroundId,
          name: backgroundName,
          status: PublishingStatus.Draft,
          date,
          draftData: draftDataObj,
          editableCanvas: isCanvasEditable,
        }),
      );
      isSnackSuccessful = res.meta.requestStatus === 'fulfilled';
      snackMsg = 'The background draft has been edited';
    }
    if (isSnackSuccessful) navigate(`/background${isBackgroundByGroup ? '' : '#individual'}`);
    setIsLoadingScreen(false);
    setSnackPack((prev) => [...prev, { message: snackMsg, successful: isSnackSuccessful, key: new Date().getTime() }]);
  };

  const handleFileChosen = async (file: File, bgImgId: number, draftImgsArr: ImgProps[]) => {
    fileReader = new FileReader();
    fileReader.onloadend = () => handleFileCRUD(draftImgsArr, bgImgId);
    fileReader.readAsArrayBuffer(file);
  };

  const uploadBackgroundImageToS3 = async (
    draftImagesArray: ImgProps[],
    importedImgUrl?: string,
    isDraft?: boolean,
  ) => {
    const imgFileName = uuidv4(); // creates a unique filename string for identification
    const customBackgroundImgFile: File = importedImgUrl // Updates and uploads bg img but without draft imgs bc they're tainted
      ? await createFileFromUrl(importedImgUrl, imgFileName)
      : await convertCanvasToImageFile(imgFileName);
    try {
      setIsLoadingScreen(true); // Set all buttons to disabled to avoid user interaction while the request is loading
      const newFile = await fileService.uploadFile(customBackgroundImgFile, customBackgroundImgFile.name);
      if (importedImgUrl && isDraft) await handleDraftCRUD(draftImagesArray, Number(newFile.id));
      else await handleFileChosen(customBackgroundImgFile, Number(newFile.id), draftImagesArray);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchBackgroundFromS3 = async (fileId: number) => {
    const { data: s3File } = await fileService.getById(fileId);
    setCustomBackgroundImgUrl(s3File.url);
  };

  // =========================  F O R M   H A N D L E R S  ==========================

  const audienceOptions = avialableAudience.map((audience) => {
    const value = isBackgroundByGroup ? (audience as Group).name : (audience as SynkronUser).fullName;
    return {
      label: value,
      value,
    };
  });

  const handleNameChange = (event: SyntheticEvent) => {
    const { value } = event.target as HTMLTextAreaElement;
    setBackgroundName(value);
    setIsNameInvalid(value.length > MAX_NAME_LENGTH);
  };

  const handleAudienceChange = (event: SelectChangeEvent<string | string[]>) => {
    const {
      target: { value },
    } = event;
    setSelectedAudienceArr(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(', ') : value,
    );
  };

  const handleDurationChange = (event: SelectChangeEvent<string | string[]>) => {
    const { value } = event.target as HTMLSelectElement;
    if (isBackgroundPublished) {
      setIsEditDurationInvalid(Number(value) < backgroundSelected?.duration);
    }
    setSelectedDuration(value);
  };

  const handleDateChange = (newDate: Dayjs | null | undefined) => {
    setIsDateInvalid(true);
    if (newDate?.isValid()) {
      setDate(newDate);
      setIsDateInvalid((newDate?.isToday() && today > MAX_TODAY_PUBLISHING_TIME) || newDate <= today);
    }
  };

  // Handlers for publishing or saving the background on the create and edit services
  const handlePublishBackground = async () => {
    let finalImagesArray: ImgProps[] = [];
    if (draftImagesArr.length) finalImagesArray = await uploadDraftImagesToS3(draftImagesArr);
    // When we're publishing a previously saved as draft bg that contained an import an img image
    if (backgroundSelected?.image) {
      const {
        data: { url },
      } = await fileService.getById(backgroundSelected?.image);
      uploadBackgroundImageToS3(finalImagesArray, url);
    }
    // When we're publishing a bg that may have or not a customBackgroundImgUrl
    else await uploadBackgroundImageToS3(finalImagesArray, customBackgroundImgUrl);
  };

  const handleUpdateScheduledBackground = async () => {
    let finalImagesArray: ImgProps[] = [];
    // Only true when original canvas was from scratch
    if (draftImagesArr.length) finalImagesArray = await uploadDraftImagesToS3(draftImagesArr);
    if (backgroundSelected?.image && !isCanvasEditable) {
      await handleFileCRUD(finalImagesArray, backgroundSelected?.image);
    } else {
      await uploadBackgroundImageToS3(finalImagesArray);
    }
  };

  const handleSaveBackground = async () => {
    setIsLoadingScreen(true);
    let finalImagesArray: ImgProps[] = [];
    if (draftImagesArr.length) finalImagesArray = await uploadDraftImagesToS3(draftImagesArr);
    if (customBackgroundImgUrl) {
      // When there's already a saved bg image
      if (backgroundSelected?.image) await handleDraftCRUD(finalImagesArray, backgroundSelected?.image);
      else uploadBackgroundImageToS3(finalImagesArray, customBackgroundImgUrl, true);
    } else {
      // when starting from scratch
      await handleDraftCRUD(finalImagesArray);
    }
  };

  // ============================  U S E   E F F E C T  ============================

  // Fetch the correct type of audience
  useEffect(() => {
    if (isBackgroundByGroup) {
      dispatch(readAllGroups());
    } else dispatch(readAllUsers());
  }, [dispatch, isBackgroundByGroup]);

  // Fetch the intial values state of the Background (new or existing)
  useEffect(() => {
    if (!isServiceCreate) {
      dispatch(readBackgroundById(backgroundId));
    } else {
      dispatch(clearSelectedBackground());
    }
  }, [backgroundId, dispatch, isServiceCreate]);

  // When editing the form, set the current info in the inputs as the current values
  useEffect(() => {
    if (!isServiceCreate && backgroundSelected) {
      const { name, AudienceBackground, duration, date: bgDate } = backgroundSelected;
      setBackgroundName(name);
      const bgAudience: string[] = [];
      AudienceBackground.forEach(({ fullName, group }) => {
        if (group) {
          if (!bgAudience.includes(group.name)) {
            bgAudience.push(group.name);
          }
        } else if (!bgAudience.includes(fullName)) {
          bgAudience.push(fullName);
        }
      });
      setSelectedAudienceArr(bgAudience);
      setSelectedDuration(`${duration}`);
      setDate(dayjs(bgDate));
      if (customBackgroundImgId) fetchBackgroundFromS3(customBackgroundImgId);
    }
  }, [backgroundSelected, isServiceCreate, customBackgroundImgId]);

  // Updates the selectedAudienceIdsArr values used for services when selectedAudienceArr changes
  useEffect(() => {
    if (isBackgroundByGroup) {
      const audienceIds: number[] = [];
      selectedAudienceArr.forEach((selectedAudienceName) => {
        (avialableAudience as Group[]).forEach(({ id, name: audienceName }) => {
          if (selectedAudienceName === audienceName) audienceIds.push(id);
        });
      });
      setSelectedAudienceIdsArr(audienceIds);
    }
  }, [avialableAudience, selectedAudienceArr, isBackgroundByGroup]);

  // Validates when the publish or save changes buttons are disabled
  useEffect(() => {
    setIsTitleChipDisabled(!hideTitleBox);
    setIsSubtitleChipDisabled(!hideSubtitleBox);
    setIsPublishDisabled(isPublishDisabledValidation);
    setIsSaveDraftDisabled(!isSaveDraftDisabledValidation);
    setIsSaveChangesDisabled(!isSaveChangesDisabledValidation);
  }, [
    hideSubtitleBox,
    hideTitleBox,
    isSaveDraftDisabledValidation,
    isPublishDisabledValidation,
    isSaveChangesDisabledValidation,
  ]);

  return (
    <ThemeProvider theme={azulDesign}>
      <Fragment>
        {/* If we're creating a new background, show the bg form, otherwise if we're editing or Loading, show the spinner while the bg image loads */}
        {(!isServiceCreate && isLoadingScreen) || isBackgroundLoading || isLoadingScreen ? (
          <AzulLoadingState
            subtitle='We are loading your background, this will take a few seconds.'
            title='Please wait. Loading your Background'
          />
        ) : (
          <Box sx={{ display: 'flex', width: '75.25%', marginTop: '6rem' }}>
            {/* =========================  L E F T   S I D E B A R  ======================== */}
            <aside className={styles.left}>
              {/* TO DO: eventually add just ONE modal and leave all buttons that can trigger it on this view */}
              <FormConfirmationModal
                isFormByGroup={isBackgroundByGroup}
                isServiceCreate={isServiceCreate}
                label='Background'
                leaveForm
                primaryActionLabel='Publish'
                secondaryActionLabel={isServiceCreate ? 'Save as draft' : 'Save changes'}
                secondaryDisabled={isServiceCreate ? isSaveDraftDisabled : isSaveChangesDisabled}
                onPublish={async (isDraft) => {
                  if (isDraft) {
                    if (isBackgroundScheduled) await handleUpdateScheduledBackground();
                    else await handleSaveBackground();
                  } else await handlePublishBackground();
                }}
              />
            </aside>
            <main className={styles.mainContainer}>
              {/* ==========================  H E A D E R  ============================== */}
              <header>
                <Typography variant='viewHeader'>
                  {' '}
                  {isServiceCreate ? 'Create a New' : 'Edit'} {audienceType} Background
                </Typography>
                <Typography sx={{ marginTop: '8px' }} variant='paragraph14'>
                  <Typography sx={{ fontWeight: 600, display: 'inline-block' }} variant='paragraph14'>
                    Current background:
                  </Typography>{' '}
                  {backgroundName || '(No Background Name)'}
                </Typography>
                {/* ===========================  C H I P S  ============================== */}
                <Box
                  className={styles.chipsBox}
                  sx={{ display: isCanvasEditable ? 'flex' : 'none' }} // Chips are hidden for "import an image" mode
                >
                  {/* Group of choice chips with a particular theme, they allow customizing the canvas  */}
                  <ThemeProvider theme={azulDesign}>
                    <Button aria-describedby={idColorPicker} variant='azulChip' onClick={handleClickChangeColor}>
                      change color
                    </Button>
                    <Button disabled={isTitleChipDisabled} variant='azulChip' onClick={() => handleOnAddText(true)}>
                      add title
                    </Button>
                    <Button disabled={isSubtitleChipDisabled} variant='azulChip' onClick={() => handleOnAddText(false)}>
                      add subtitle
                    </Button>
                    <AddImageButton // renamed the CreateBackgroundModal component on import
                      addCanvasImage
                      addImage={async (imgFileUrl) => loadImageToCanvas(imgFileUrl)} // Can additionally take in a file
                      audienceType={isBackgroundByGroup ? 'groups' : 'individuals'}
                    />
                    {/* TODO: uncommment this Box in case we need a temporary preview of the uploaded img file for the currently selected bg */}
                    {/* <Box>
                      <img alt='preview' height={50} src={customBackgroundImgUrl} width={100} />
                    </Box> */}
                  </ThemeProvider>
                </Box>
              </header>
              {/* ===========================  C A N V A S  ============================== */}
              {/* Background color picker */}
              <ColorPicker
                anchorEl={anchorEl}
                color={colorValue}
                open={open}
                pickerId={idColorPicker}
                onChange={setColorValue}
                onClose={handleCloseBackgroundColorPicker}
              />
              <Box className={styles.canvasBox}>
                <Canvas
                  chosenSubtitleCoordinates={handleSubtitleCoordinates}
                  chosenTextColors={handleTexColors}
                  chosenTitleCoordinates={handleTitleCoordinates}
                  customBackgroundImgUrl={isCanvasEditable ? undefined : customBackgroundImgUrl}
                  hideSubtitleBox={hideSubtitleBox}
                  hideTitleBox={hideTitleBox}
                  setShowTextTools={setShowTextTools}
                  showTextTools={showTextTools}
                  onTextDelete={handleOnDeleteText}
                >
                  <DragAndResizeImages
                    draftImagesArray={(imgs) => {
                      setDraftImagesArr(imgs);
                    }}
                    imageCount={(count) => setImagesArrCount(count)}
                    imagesToRender={imagesArr}
                    resetImagesArray={() => setImagesArr([])}
                  />
                </Canvas>
              </Box>
              {/* =============================  F O R M  ============================== */}
              <form>
                <Box className={styles.formBox}>
                  {/* ============================== NAME INPUT ============================== */}
                  <AzulInput
                    error={isNameInvalid}
                    helperText={`${backgroundName.length}/${MAX_NAME_LENGTH}`}
                    helperTextAlign='end'
                    id='background-name'
                    label='Background Name'
                    value={backgroundName}
                    width='47.5%'
                    onChange={handleNameChange}
                  />
                  {/* ============================== AUDIENCE SELECT ============================== */}
                  <AzulSelect
                    IconComponent={ArrowDownIcon}
                    id='audience-select'
                    label='Choose audience'
                    labelId='audience-select-label'
                    multiple
                    options={audienceOptions}
                    searchBar
                    value={selectedAudienceArr}
                    width='47.5%'
                    onChange={handleAudienceChange}
                  />
                </Box>

                <Box className={styles.formBox}>
                  {/* ============================== DURATION SELECT ============================== */}
                  <AzulSelect
                    IconComponent={ArrowDownIcon}
                    error={isEditDurationInvalid}
                    helperText={isEditDurationInvalid ? 'Currently only to extend the duration.' : ''}
                    id='duration-select'
                    label='Publication Duration'
                    labelId='duration-select-label'
                    options={DURATIONS}
                    value={selectedDuration}
                    width='47.5%'
                    onChange={handleDurationChange}
                  />

                  {/* ============================== DATE PICKER ============================== */}
                  <AzulDatePicker
                    disablePast
                    disabled={isBackgroundPublished}
                    helperText={isDateInvalid ? 'Please choose a valid date.' : 'MM/DD/YYYY'}
                    label='Publication Date'
                    value={date}
                    width='47.5%'
                    onChange={handleDateChange}
                  />
                </Box>
              </form>

              {/* ==========================  F O O T E R   B U T T O N S  =========================== */}
              <footer>
                <FormConfirmationModal
                  isFormByGroup={isBackgroundByGroup}
                  isServiceCreate={isServiceCreate}
                  label='Background'
                  primaryActionLabel='Publish'
                  primaryDisabled={isPublishDisabled}
                  secondaryActionLabel={isServiceCreate ? 'Save as draft' : 'Save changes'}
                  secondaryDisabled={isServiceCreate ? isSaveDraftDisabled : isSaveChangesDisabled}
                  onPublish={async (isDraft) => {
                    if (isDraft) {
                      if (isBackgroundScheduled) await handleUpdateScheduledBackground();
                      else await handleSaveBackground();
                    } else await handlePublishBackground();
                  }}
                />
              </footer>
            </main>
          </Box>
        )}
        <SimpleSnackbar />
      </Fragment>
    </ThemeProvider>
  );
};

export default BackgroundForm;
