export const CATEGORIES = [
  {
    icon: 'campaign',
    label: 'For All',
    value: '6. For All',
  },
  {
    icon: 'account_tree',
    label: 'Area Info Related',
    value: '1. Area Info Related',
  },
  {
    icon: 'forum',
    label: 'Community',
    value: '2. Community',
  },
  {
    icon: 'computer',
    label: 'Training',
    value: '3. Training',
  },
  {
    icon: 'volunteer_activism',
    label: 'Activities',
    value: '4. Activities',
  },
  {
    icon: 'celebration',
    label: 'Social',
    value: '5. Social',
  },
];

export const getCategoryLabel = (id: number, addId?: boolean) => {
  const categoryMap: Record<number, string> = {
    1: 'Area Information Related',
    2: 'Community',
    3: 'Training',
    4: 'Activities',
    5: 'Social',
    6: 'For All',
  };

  const label = categoryMap[id] || '';

  return addId ? `${id}. ${label}` : label;
};
